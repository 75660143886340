import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'
import 'swiper/css/effect-coverflow'

import { EffectFade, Mousewheel } from 'swiper'
import { MainButton1, MainButton2 } from 'styles/common/button'
import { MainWrap, SubSection1, SubSection2, SubSection4, SubSection5 } from 'styles/views/Main'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useEffect, useRef, useState } from 'react'

import { Link } from 'react-router-dom'
import Lottie from 'lottie-react'
import { Parallax } from 'react-parallax'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import TopButton from '../component/common/TopButton'
import antiscamIcon from '../assets/icon/service/img_sfb_76.png'
import antiscamImage1 from '../assets/images/main/img_Section-01_anti_1920.png'
import antiscamImage2 from '../assets/images/main/img_Section-01_anti_1200.png'
import arrow from 'assets/icon/Arrow 1 (Stroke).png'
import arrowIcon from 'assets/icon/arrow-circle.png'
import autocallIcon from '../assets/icon/service/img_auto_76.png'
import autocallImage1 from '../assets/images/main/img_Section-03_auto.png'
import couponWalletFullPageImg from '../assets/images/main/img_Section-06_coupon.png'
import couponWalletIcon from '../assets/icon/service/img_cp_76.png'
import couponWalletImage1 from '../assets/images/main/img_Section-04_coin.png'
import eventuIcon from '../assets/icon/service/img_event_76.png'
import eventuImage1 from '../assets/images/main/img_Section-05_eventu.png'
import familycareFullPageImg from '../assets/images/main/familycare_fullpage_img.png'
import familycareIcon from '../assets/icon/service/img_care_76.png'
import { getPatentAndNewsList } from 'service/fetchs'
import gsap from 'gsap'
import { headerBlurFlag } from 'store/index'
import historyImg from 'assets/images/main/glass-office-building-view-of-the-sky-reflected-in-the-windows 2.png'
import lottie from 'lottie-web'
import mainMotion from 'assets/json/datauniverse-main-logo.json'
import mainServiceBg from 'assets/video/main_service_bg.mp4'
import mainVideo1 from 'assets/video/DU_main01.mp4'
import mainVideo2 from 'assets/video/DU_main02.mp4'
import mainVideo3 from 'assets/video/DU_main03.mp4'
import mfinderIcon from '../assets/icon/service/img_find_76.png'
import mfinderImage1 from '../assets/images/main/img_Section-02_mfinder.png'
import patentImg from 'assets/images/main/image 286.png'
import recruitImg from 'assets/images/main/img_home-recruit_1600.png'
import serviceMotion1 from '../assets/json/img_Section-01_pin_160.json'
import serviceMotion2 from '../assets/json/img_Section-02_pin_710.json'
import serviceMotion3 from '../assets/json/img_Section-03_car_700.json'
import serviceMotion4 from '../assets/json/img_Section-03_smile_250.json'
import serviceMotion5 from '../assets/json/img_Section-04_heart_700.json'
import serviceMotion6 from '../assets/json/img_Section-05_box1_1000.json'
import serviceMotion7 from '../assets/json/img_Section-05_box2_1200.json'
import serviceMotion8 from '../assets/json/img_Section-04_wallet_700.json'
import { useMediaQuery } from 'react-responsive'
import { useSetRecoilState } from 'recoil'

gsap.registerPlugin(ScrollTrigger)

const Home = () => {
  const [mainNews, setMainNews] = useState()
  const [selectVideo, setSelectVideo] = useState()
  const [sloganFlag, setSloganFlag] = useState(true)
  const [touchHeight, setTouchHeight] = useState(0)
  const [swiperIndex, setSwiperIndex] = useState(0)
  const [videoFlag, setVideoFlag] = useState(false)
  const setBlur = useSetRecoilState(headerBlurFlag)
  const historyRef = useRef()
  const patentRef = useRef()
  const swiperRef = useRef()
  const carLottieRef = useRef()

  const isMobile = useMediaQuery({
    query: '(min-width:1200px)',
  })
  const isMobile2 = useMediaQuery({
    query: '(max-width:1199px)',
  })

  const videoSource = [
    { idx: 0, source: mainVideo1 },
    { idx: 1, source: mainVideo2 },
    { idx: 2, source: mainVideo3 },
  ]
  const video = localStorage.getItem('mainVideo')

  const enableSwiper = () => {
    const swiper = document.getElementsByClassName('swiper-vertical')[0]
    swiper.style.touchAction = 'pan-x'
    swiperRef.current.swiper?.enable()
    setBlur(false)
    gsap.to('.eventu_image_wrapper', { y: '0', duration: 1 })
  }

  const disableSwiper = () => {
    const swiper = document.getElementsByClassName('swiper-vertical')[0]
    swiper.style.touchAction = 'pan-y'
    swiperRef.current.swiper?.disable()
  }

  const handleMouseEnter = (type) => {
    switch (type) {
      case 'history':
        historyRef.current.classList.add('active')
        break
      case 'patent':
        patentRef.current.classList.add('active')
        break
      default:
        break
    }
  }

  const handleMouseLeave = (type) => {
    switch (type) {
      case 'history':
        historyRef.current.classList.remove('active')
        break
      case 'patent':
        patentRef.current.classList.remove('active')
        break
      default:
        break
    }
  }

  const getNewsData = async () => {
    const mainNewsData = await getPatentAndNewsList('NEWS', 2, 0)
    setMainNews(mainNewsData[0])
  }

  const popIconClick = () => {
    if (swiperRef.current.swiper.isEnd) {
      goStory()
      setBlur(true)
      disableSwiper()
    } else {
      swiperRef.current.swiper.slideToLoop(swiperIndex + 1)
    }
  }

  const elementRef = useRef(null)
  const scrollHandler = (e) => {
    let st = window.scrollY || document.documentElement.scrollTop
    if (e.type === 'wheel') {
      if (st === 0 && !swiperRef.current.swiper?.enabled && e.deltaY < 0) {
        enableSwiper()
      }
      return
    }
    if (st === 0 && !swiperRef.current.swiper.enabled) {
      enableSwiper()
    } else {
      /*console.warn(`scrollHandler: st: ${st}, enabled: ${swiperRef.current.swiper.enabled}` )
      disableSwiper()*/
    }

    if (st > 0 && !swiperRef.current.swiper.enabled) {
      setBlur(true)
      /*gsap.to('.eventu_image_wrapper',
        {y: '-100%', duration: 1}
      )*/
    }
  }

  let startTouchY = 0
  let endTouchY = 0
  const touchStartHandler = (e) => {
    startTouchY = e.changedTouches[0].clientY
  }

  const touchEndHandler = (e) => {
    endTouchY = e.changedTouches[0].clientY
    setTouchHeight(Math.trunc(startTouchY) - Math.trunc(endTouchY))
  }

  useEffect(() => {
    if (touchHeight > 0 && swiperRef.current.swiper.enabled && swiperRef.current.swiper.isEnd) {
      disableSwiper()
    } else if (!swiperRef.current.swiper.enabled && window.scrollY === 0) {
      enableSwiper()
    }
  }, [touchHeight])

  useEffect(() => {
    setBlur(false)
    getNewsData()

    lottie.loadAnimation({
      container: document.querySelector('.test_lottie'),
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: mainMotion,
    })

    if (!video || video === '2') {
      localStorage.setItem('mainVideo', '0')
      setSelectVideo(videoSource[0].source)
    } else {
      localStorage.setItem('mainVideo', parseInt(video) + 1)
      const temp = videoSource.filter((s) => s.idx === parseInt(video) + 1)
      setSelectVideo(temp[0].source)
    }

    window.addEventListener('scroll', scrollHandler)
    window.addEventListener('wheel', scrollHandler)
    window.addEventListener('touchstart', touchStartHandler)
    window.addEventListener('touchend', touchEndHandler)

    return () => {
      window.removeEventListener('scroll', scrollHandler)
      window.removeEventListener('wheel', scrollHandler)
      window.removeEventListener('touchstart', touchStartHandler)
      window.removeEventListener('touchend', touchEndHandler)
    }
  }, [])

  useEffect(() => {
    setTimeout(function () {
      hideAllAni()
      if (swiperIndex > 0) {
        setVideoFlag(true)
      } else {
        setVideoFlag(false)
      }
      if (swiperIndex === 1) {
        if (sloganFlag) {
          commonAni()
          setSloganFlag(false)
        }
      } else {
        commonAni()
      }
    }, 950)
  }, [swiperIndex])

  const hideAllAni = () => {
    const imageWrappers = document.getElementsByClassName('image_wrapper')
    for (let i = 0; i < imageWrappers.length; i++) {
      imageWrappers[i].style.opacity = 0
    }
  }

  const commonAni = () => {
    const check = swiperRef.current.swiper.previousIndex - swiperIndex
    const aniTarget = document.querySelectorAll('.image_wrapper')[swiperIndex - 2]
    if (swiperIndex === 1) {
      const fadeinEls = document.querySelectorAll('.caption')
      fadeinEls.forEach(function (textanimation, index) {
        gsap.fromTo(
          textanimation,
          { opacity: 0, y: 80 },
          { opacity: 1, y: 0, duration: 0.7, delay: index === 2 ? 0.1 : (index + 1) * 0.1 },
        )
      })
    } else if (check < 0) {
      // 위로 올라감
      gsap.fromTo(
        aniTarget,
        { opacity: 0, y: '100%' },
        { opacity: 1, y: 0, duration: 0.7, onComplete: goLottie },
      )
    } else {
      // 아래로 내려감
      gsap.fromTo(
        aniTarget,
        { opacity: 0, y: '-100%' },
        { opacity: 1, y: 0, duration: 0.7, onComplete: goLottie },
      )
    }
  }

  const goLottie = () => {
    switch (swiperIndex) {
      case 3:
        if (carLottieRef?.current.animationItem.playCount === 0) {
          carLottieRef.current.animationItem.playCount = 1
          carLottieRef.current.setSpeed(2)
          carLottieRef.current.goToAndPlay(0, true)
        }
        break
      default:
        break
    }
  }

  const goStory = () => {
    const section = document.querySelector('.story_section')

    const toScroll = section.offsetTop - document.querySelector('.header').offsetHeight

    window.scrollTo({ top: toScroll, behavior: 'smooth' })
  }

  return (
    <div>
      <MainWrap className="main_wrap">
        {videoFlag && (
          <div className={'main_service_bg'}>
            <video
              className="video"
              src={mainServiceBg}
              autoPlay={true}
              loop
              muted
              playsInline="playsinline"
            />
          </div>
        )}
        <Swiper
          ref={swiperRef}
          direction={'vertical'}
          slidesPerView={1}
          mousewheel={{
            forceToAxis: true,
            sensitivity: 1,
          }}
          speed={1000}
          modules={[Mousewheel, EffectFade]}
          allowTouchMove={true}
          // effect={'fade'}
          // fadeEffect={{
          //   crossFade: true
          // }}
          // touchReleaseOnEdges={true}
          onSlideChange={(swiper) => {
            setTimeout(function () {
              setSwiperIndex(swiper.activeIndex)
            }, 50)
          }}
          onReachEnd={(swiper) => {
            setTimeout(function () {
              disableSwiper()
            }, 1000)
          }}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="main_banner_section">
              <video
                className="video"
                src={selectVideo}
                autoPlay={true}
                loop
                muted
                playsInline="playsinline"
              />
              <div className="test_lottie" style={{ position: 'absolute' }}></div>
              <div className="pop_icon" onClick={popIconClick}>
                <img src={arrowIcon} alt="" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <SubSection1 className="du_made_section">
              <div className="container" style={{ position: 'relative', height: '100%' }}>
                <div className="content_wrap slogan">
                  <div className={'contents'}>
                    <h2 className={'caption'}>DU Made</h2>
                    <div className="content caption">
                      <p>
                        {isMobile2 ? '' : '데이터유니버스는 '}금융사기, 휴대폰분실, 개인정보유출,
                        가족돌봄 및 소통 부족 등 {isMobile2 ? '' : <br />}
                        사회문제를 해결하기 위해 정보통신기술(ICT) 분야의 기술력과 서비스 경험을
                        바탕으로 {isMobile2 ? '' : <br />}웹 3.0시대에 맞는 지능적이고 발전된 Global
                        서비스를 만들어갑니다.
                      </p>
                      <MainButton2 className="du_button caption">
                        <Link to="information" state={{ state: 3 }}>
                          더 나은 세상을 위한 DU의 발자취
                        </Link>
                      </MainButton2>
                    </div>
                  </div>
                </div>
                <div className="pop_icon" onClick={popIconClick}>
                  <img src={arrowIcon} alt="" />
                </div>
              </div>
            </SubSection1>
          </SwiperSlide>
          <SwiperSlide>
            {/*{
                (isIos) &&
                  <Lottie
                      animationData={serviceBackground}
                      style={{zIndex:'0', position:'absolute'}}
                  />
            }*/}
            <section className="service service1">
              <div className="lottie_bg lottie_color1"></div>
              <div className="mfinder_section">
                <div className={'image_contents'}>
                  <div className="image_wrapper mfinder_image_wrapper">
                    <img src={mfinderImage1} alt="" className="image1" />
                    <Lottie animationData={serviceMotion2} className="pin2" />
                  </div>
                </div>
                <div className="desc_container" style={{ position: 'relative', height: '100%' }}>
                  <div className="left_section">
                    <p className="service_title">
                      잃어버린 휴대폰을
                      <br />
                      신속하게 찾아주는!
                    </p>
                    <div className="bottom_area">
                      <div className="icon_area">
                        <img src={mfinderIcon} alt="" />
                        <span className="service_name">휴대폰분실보호</span>
                      </div>
                      <MainButton2 className="du_button">
                        <Link to={'/service'} state={{ section: 1 }}>
                          자세히 보기
                        </Link>
                      </MainButton2>
                    </div>
                  </div>
                </div>
              </div>
              <div className={'shadow'}></div>
              <div className="pop_icon" onClick={popIconClick}>
                <img src={arrowIcon} alt="" />
              </div>
            </section>
          </SwiperSlide>
          <SwiperSlide>
            {/*{
                (isIos) &&
                  <Lottie
                      animationData={serviceBackground}
                      style={{zIndex:'0', position:'absolute'}}
                  />
            }*/}
            <section className="service service2">
              <div className="lottie_bg lottie_color2"></div>
              <div className="anti_section">
                <Lottie
                  animationData={serviceMotion1}
                  style={{ zIndex: '0', position: 'absolute' }}
                  className={'pin'}
                />
                <div className={'image_contents'}>
                  <div className="image_wrapper anti_image_wrapper">
                    {isMobile ? (
                      <img src={antiscamImage1} alt="" className="image1" />
                    ) : (
                      <img src={antiscamImage2} alt="" className="image1" />
                    )}
                  </div>
                </div>
                <div className="desc_container" style={{ position: 'relative', height: '100%' }}>
                  <div className="left_section">
                    <p className="service_title">
                      보이스피싱으로부터
                      <br />
                      나와 가족을 안전하게!
                    </p>
                    <div className="bottom_area">
                      <div className="icon_area">
                        <img src={antiscamIcon} alt="" />
                        <span className="service_name">스마트피싱보호</span>
                      </div>
                      <MainButton2 className="du_button">
                        <Link to={'/service'} state={{ section: 2 }}>
                          자세히 보기
                        </Link>
                      </MainButton2>
                    </div>
                  </div>
                </div>
              </div>
              <div className={'shadow'}></div>
              <div className="pop_icon" onClick={popIconClick}>
                <img src={arrowIcon} alt="" />
              </div>
            </section>
          </SwiperSlide>
          <SwiperSlide>
            {/*{
                (isIos) &&
                  <Lottie
                      animationData={serviceBackground}
                      style={{zIndex:'0', position:'absolute'}}
                  />
            }*/}
            <section className="service service3">
              <div className="lottie_bg lottie_color3"></div>
              <div className="familycare_section">
                <div className={'image_contents'}>
                  <div className={'image_wrapper familycare_image_wrapper'}>
                    <Lottie animationData={serviceMotion5} className="heart" />
                    <img src={familycareFullPageImg} alt="" className="familycareImg" />
                  </div>
                </div>
                <div className="desc_container" style={{ position: 'relative', height: '100%' }}>
                  <div className="left_section">
                    <p className="service_title">
                      언제 어디서든
                      <br />
                      우리가족 안부확인!
                    </p>
                    <div className="bottom_area">
                      <div className="icon_area">
                        <img src={familycareIcon} alt="" />
                        <span className="service_name">휴대폰가족보호</span>
                      </div>
                      <MainButton2 className="du_button">
                        <Link to={'/service'} state={{ section: 3 }}>
                          자세히 보기
                        </Link>
                      </MainButton2>
                    </div>
                  </div>
                </div>
              </div>
              <div className={'shadow'}></div>
              <div className="pop_icon" onClick={popIconClick}>
                <img src={arrowIcon} alt="" />
              </div>
            </section>
          </SwiperSlide>
          <SwiperSlide>
            {/*{
                (isIos) &&
                  <Lottie
                      animationData={serviceBackground}
                      style={{zIndex:'0', position:'absolute'}}
                  />
            }*/}
            <section className="service service4">
              <div className="lottie_bg lottie_color4"></div>
              <div className="familycare_section">
                <div className={'image_contents'}>
                  <div className={'image_wrapper '}>
                    <Lottie animationData={serviceMotion8} className="wallet" />
                    <img src={couponWalletFullPageImg} alt="" className="couponWalletImg image1" />
                    <img src={couponWalletImage1} alt="" className="couponWallet1" />
                  </div>
                </div>
                <div className="desc_container" style={{ position: 'relative', height: '100%' }}>
                  <div className="left_section">
                    <p className="service_title">
                      모바일 쿠폰 매일 할인 중
                      <br />
                      관리까지 스마트하게!
                    </p>
                    <div className="bottom_area">
                      <div className="icon_area">
                        <img src={couponWalletIcon} alt="" />
                        <span className="service_name">휴대폰쿠폰지갑</span>
                      </div>
                      <MainButton2 className="du_button">
                        <Link to={'/service'} state={{ section: 4 }}>
                          자세히 보기
                        </Link>
                      </MainButton2>
                    </div>
                  </div>
                </div>
              </div>
              <div className={'shadow'}></div>
              <div className="pop_icon" onClick={popIconClick}>
                <img src={arrowIcon} alt="" />
              </div>
            </section>
          </SwiperSlide>
          <SwiperSlide>
            <section ref={elementRef} className="service service5" id="section1">
              <div className="lottie_bg lottie_color5"></div>
              <div className="eventu_section">
                <div className={'image_contents'}>
                  <div className={'image_wrapper eventu_image_wrapper'}>
                    <Lottie animationData={serviceMotion6} className={'box1'} />
                    <Lottie animationData={serviceMotion7} className={'box2'} />
                    <img src={eventuImage1} alt="" className="image1" />
                  </div>
                </div>
                <div className="desc_container" style={{ position: 'relative', height: '100%' }}>
                  <div className="left_section">
                    <p className="service_title">
                      365일 쏟아지는
                      <br />
                      이벤트와 혜택!
                    </p>
                    <div className="bottom_area">
                      <div className="icon_area">
                        <img src={eventuIcon} alt="" />
                        <span className="service_name">EVENT·U</span>
                      </div>
                      <MainButton2 className="du_button">
                        <Link to={'/service'} state={{ section: 5 }}>
                          자세히 보기
                        </Link>
                      </MainButton2>
                    </div>
                  </div>
                </div>
              </div>
              <div className={'shadow'}></div>
              <div className="pop_icon" onClick={popIconClick}>
                <img src={arrowIcon} alt="" />
              </div>
            </section>
          </SwiperSlide>
          <SwiperSlide>
            {/*{
                (isIos) &&
                  <Lottie
                      animationData={serviceBackground}
                      style={{zIndex:'0', position:'absolute'}}
                  />
            }*/}
            <section className="service service6">
              <div className="lottie_bg lottie_color6"></div>
              <div className="autocall_section">
                <div className={'image_contents'}>
                  <div className={'image_wrapper autocall_image_wrapper'}>
                    <Lottie
                      lottieRef={carLottieRef}
                      animationData={serviceMotion3}
                      className="car"
                      loop={false}
                      autoplay={false}
                    />
                    <Lottie animationData={serviceMotion4} className="smile" />
                    <img src={autocallImage1} alt="" className="image1" />
                  </div>
                </div>
                <div className="desc_container" style={{ position: 'relative', height: '100%' }}>
                  <div className="left_section">
                    <p className="service_title">
                      번호노출, 통화없는
                      <br />
                      운전자 호출 서비스!
                    </p>
                    <div className="bottom_area">
                      <div className="icon_area">
                        <img src={autocallIcon} alt="" />
                        <span className="service_name">오토콜</span>
                      </div>
                      <MainButton2 className="du_button">
                        <Link to={'/service'} state={{ section: 6 }}>
                          자세히 보기
                        </Link>
                      </MainButton2>
                    </div>
                  </div>
                </div>
              </div>
              <div className={'shadow'}></div>
              <div className="pop_icon" onClick={popIconClick}>
                <img src={arrowIcon} alt="" />
              </div>
            </section>
          </SwiperSlide>
        </Swiper>
        {/*<SubSection3 className="service_section">*/}
        {/*  <LottieServiceComponent/>*/}
        {/*</SubSection3>*/}

        <SubSection4 className="ss container story_section">
          <h2 className="title">Our Story</h2>
          <div className="story_box">
            <Link
              className="story_item"
              ref={historyRef}
              onMouseEnter={() => handleMouseEnter('history')}
              onMouseLeave={() => handleMouseLeave('history')}
              to={'information'}
              state={{ state: 3 }}
            >
              <div className="box">
                <figure className="img_box">
                  <img src={historyImg} alt="" />
                </figure>
              </div>
              <div className="story_content">
                <h3>히스토리</h3>
                <figure>
                  <img src={arrow} alt="" />
                </figure>
              </div>
              <p>더 나은 세상을 위한 노력</p>
            </Link>
            <Link
              className="story_item"
              ref={patentRef}
              onMouseEnter={() => handleMouseEnter('patent')}
              onMouseLeave={() => handleMouseLeave('patent')}
              to={'information'}
              state={{ state: 2 }}
            >
              <div className="box">
                <figure className="img_box">
                  <img src={patentImg} alt="" />
                </figure>
              </div>
              <div className="story_content patent">
                <h3>특허 및 인증</h3>
                <figure>
                  <img src={arrow} alt="" />
                </figure>
              </div>
              <p>끊임없는 도전 및 혁신</p>
            </Link>
          </div>
        </SubSection4>
        <SubSection2 className="ss news_section">
          <div className="container">
            <div className="content_wrap">
              <h2>News</h2>
              <div className="news_wrap">
                <h3>{mainNews?.Title}</h3>
                <p className="news_content">{mainNews?.Description}</p>
                <MainButton1 className="news_button">
                  <a href={mainNews?.LinkPath} target="_blank" rel="noreferrer">
                    뉴스 기사 보기
                  </a>
                </MainButton1>
              </div>
            </div>
          </div>
        </SubSection2>
        <SubSection5 className="ss recruit_section container">
          <Parallax
            className="recruit"
            bgImage={recruitImg}
            strength={300}
            bgImageStyle={{ minWidth: '1095px', minHeight: '730px' }}
          >
            <div className="">
              <h3 className="recruit_title">우리와 함께 일해요</h3>
              <div className="recruit_content">
                <p>더 나은 세상을 만들기 위해, {!isMobile && <br />}데이터유니버스와 함께</p>
              </div>
              <div className="recruit_btn">
                <MainButton2>
                  <Link to="recruit">채용안내 확인</Link>
                </MainButton2>
              </div>
            </div>
          </Parallax>
        </SubSection5>
        <TopButton scroll={swiperRef} />
      </MainWrap>
    </div>
  )
}
export default Home
