export const CmdType = {
	PATENTS: 'PATENTS',
  PATENTS_CNT: 'PATENTS_CNT',
  HISTORIES: 'HISTORIES',
  HISTORIES_CNT: 'HISTORIES_CNT',
  NEWS: 'NEWS',
  NEWS_CNT: 'NEWS_CNT'
}

export class HTTPRequest {
  Header
  Body

  constructor(CmdType, Body){
    this.Header = {
      'CmdType': CmdType
    }
    this.Body = Body
  }
}

export class HTTPRequestBody {}

export const URL = {
	DEV : {
		REST : 'https://dev-durs.datau.co.kr'
	},
	PRO : {
		REST : 'https://durs.datau.co.kr'
	}
}

export const ERROR_CODE = {
  SUCCESS: 0,
  ERROR_UNKNOWN_CMDTYPE: 900004
}