import { axiosPostData } from './utils'
import { CmdType, HTTPRequest, HTTPRequestBody } from './constant/common'
import { formatHistories } from './utils/format'

// 특허증, 뉴스 리스트 호출
export const getPatentAndNewsList = async (cmdType, type, offset, length) => {
	/**
	 * 특허
	 * 1 : 전체가져오기 | 0 : Length Offset 기준으로 쿼리 실행
	 * 뉴스
	 * 1 : 전체가져오기 | 0 : Length Offset 기준으로 쿼리 실행 | 2 : 최근뉴스 1개 | 3 : 메인뉴스 1개
	 */
	const body = new HTTPRequestBody()
	body.type = type
	body.offset = offset
	body.length = length

	const httpRequest = new HTTPRequest(CmdType[cmdType], body)

	return await axiosPostData(httpRequest)
}

// // History 리스트 호출
export const getHistoryList = async (cmdType, page, step = 3) => {
	const body = new HTTPRequestBody()
	body.page = page
	body.step = step

	const httpRequest = new HTTPRequest(CmdType[cmdType], body)

	const result = await axiosPostData(httpRequest)
	return formatHistories(result)
}

// History, Patent, NEWS toall 호출
export const getTotalNum = async (cmdType) => {
	const body = new HTTPRequestBody()
	
	const httpRequest = new HTTPRequest(CmdType[cmdType], body)
	
	return await axiosPostData(httpRequest)
}