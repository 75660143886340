import DataUniversRoute from 'router/index'
import Header from 'views/common/Header'
import Footer from 'views/common/Footer'
import 'styles/index.scss'

const App = () => {
  if (process.env.NODE_ENV === 'development') {
    console.log = function no_console() {}
  }
  return (
    <>
      <Header />
      <DataUniversRoute />
      <Footer />
    </>
  )
}

export default App
