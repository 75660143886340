import styled from 'styled-components'
import STYLE_GUIDE from 'service/constant/color'

export const DefaultButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 120px;
  font-weight: 700;
  border: 2px solid ${STYLE_GUIDE.COLOR.gray1};
  &:hover {
    cursor: pointer;
  }
`

export const MainButton1 = styled(DefaultButton)`
  padding: 12px 26px 14px 26px;
  font-size: 18px;
  transition: all 0.3s ease;
  border: 2px solid rgba(255, 255, 255, 0.5);
  a {
    color: ${STYLE_GUIDE.COLOR.white};
  }
  &:hover {
    border: 2px solid ${STYLE_GUIDE.COLOR.white};
    background: ${STYLE_GUIDE.COLOR.white};
    a {
      color: ${STYLE_GUIDE.COLOR.black2};
    }
  }
`

export const MainButton2 = styled(DefaultButton)`
  padding: 12px 26px 14px 26px;
  font-size: 18px;
  transition: all 0.3s ease;
  // border: 2px solid ${STYLE_GUIDE.COLOR.white};
  border: 2px solid rgba(255, 255, 255, 0.5);
  a {
    color: ${STYLE_GUIDE.COLOR.white};
  }
  &:hover {
    //border: 2px solid ${STYLE_GUIDE.COLOR.gray4};
    border: 2px solid rgba(244, 244, 244, 0.5);
    background: ${STYLE_GUIDE.COLOR.white};
    a {
      color: ${STYLE_GUIDE.COLOR.black2};
    }
  }
`

export const MainButton3 = styled(DefaultButton)`
  padding: 6px 20px 8px;
  font-size: 16px;
  transition: all 0.3s ease;
  border: 2px solid rgba(177, 177, 177, 0.5);
  a {
    color: ${STYLE_GUIDE.COLOR.black2};
  }
  &:hover {
    border: 2px solid ${STYLE_GUIDE.COLOR.black2};
    background: ${STYLE_GUIDE.COLOR.black2};
    color: ${STYLE_GUIDE.COLOR.white};
    a {
      color: ${STYLE_GUIDE.COLOR.white};
    }
  }
`

export const MainButton4 = styled(DefaultButton)`
  padding: 6px 20px 8px;
  font-size: 16px;
  transition: all 0.3s ease;
  a {
    color: ${STYLE_GUIDE.COLOR.white};
  }
  &:hover {
    background: ${STYLE_GUIDE.COLOR.gray4};
    a {
      color: ${STYLE_GUIDE.COLOR.black2};
    }
  }
`

export const SubButton1 = styled(DefaultButton)`
  padding: 6px 20px 8px;
  font-size: 16px;
  border: 2px solid ${STYLE_GUIDE.COLOR.gray1};
  color: ${STYLE_GUIDE.COLOR.black2};
  &:hover {
    background: ${STYLE_GUIDE.COLOR.black2};
    color: ${STYLE_GUIDE.COLOR.white};
  }
`

export const SubButton2 = styled(DefaultButton)`
  padding: 6px 16px 8px;
  font-size: 14px;
  border: 2px solid ${STYLE_GUIDE.COLOR.gray1};
  color: ${STYLE_GUIDE.COLOR.black2};
  &:hover {
    background: ${STYLE_GUIDE.COLOR.black2};
    color: ${STYLE_GUIDE.COLOR.white};
  }
`
