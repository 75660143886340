import styled, { keyframes } from 'styled-components'
import kt from 'assets/icon/partnerLogo/logo_kt_240.png'
import lg from 'assets/icon/partnerLogo/logo_lg_240.png'
import skt from 'assets/icon/partnerLogo/logo_skt_240.png'
import nh1 from 'assets/icon/partnerLogo/logo_nh1_240.png'
import tmap from 'assets/icon/partnerLogo/logo_tmap_240.png'
import parking from 'assets/icon/partnerLogo/logo_parking_240.png'
import koreaUniv from 'assets/icon/partnerLogo/logo_korea-univ_240.png'
import skp from 'assets/icon/partnerLogo/logo_skp_240.png'
import nh2 from 'assets/icon/partnerLogo/logo_nh2_240.png'
import hana from 'assets/icon/partnerLogo/logo_hana_240.png'
import woori from 'assets/icon/partnerLogo/logo_woori_240.png'
import kb from 'assets/icon/partnerLogo/logo_kb_240.png'
import thecheat from 'assets/icon/partnerLogo/logo_thecheat_240.png'
import ahnlab from 'assets/icon/partnerLogo/logo_ahnlab_240.png'
import est from 'assets/icon/partnerLogo/logo_est_240.png'
import hanabank from 'assets/icon/partnerLogo/logo_hanabank_240.png'
import sci from 'assets/icon/partnerLogo/logo_SCI_240.png'

const scroll = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100%));
  }
  `

const RollinWrap = styled.section`
  .marquee {
    position: relative;
    display: flex;
    overflow: hidden;
    user-select: none;
  }

  .top_rolling {
    padding: 0 0 80px;
  }

  .marquee_content {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    min-width: 100%;
    animation: ${scroll} 20s linear infinite;
  }

  /* Reverse animation */
  .marquee_reverse .marquee_content {
    animation-direction: reverse;
  }

  @media screen and (max-width: 1199px) {
    .rolling_item {
      width: 180px;
    }
    .top_rolling {
      padding: 0 0 50px;
    }
  }
`

const RoolingPartner = () => {
  return (
    <RollinWrap>
      <div className="marquee top_rolling">
        <ul className="marquee_content">
          <li className="rolling_item">
            <img src={kt} alt="" />
          </li>
          <li className="rolling_item">
            <img src={lg} alt="" />
          </li>
          <li className="rolling_item">
            <img src={skt} alt="" />
          </li>
          <li className="rolling_item">
            <img src={skp} alt="" />
          </li>
          <li className="rolling_item">
            <img src={tmap} alt="" />
          </li>
          <li className="rolling_item">
            <img src={sci} alt="" />
          </li>
          <li className="rolling_item">
            <img src={parking} alt="" />
          </li>
          <li className="rolling_item">
            <img src={koreaUniv} alt="" />
          </li>
        </ul>
        <ul className="marquee_content" aria-hidden="true">
          <li className="rolling_item">
            <img src={kt} alt="" />
          </li>
          <li className="rolling_item">
            <img src={lg} alt="" />
          </li>
          <li className="rolling_item">
            <img src={skt} alt="" />
          </li>
          <li className="rolling_item">
            <img src={skp} alt="" />
          </li>
          <li className="rolling_item">
            <img src={tmap} alt="" />
          </li>
          <li className="rolling_item">
            <img src={sci} alt="" />
          </li>
          <li className="rolling_item">
            <img src={parking} alt="" />
          </li>
          <li className="rolling_item">
            <img src={koreaUniv} alt="" />
          </li>
        </ul>
      </div>
      <div className="marquee marquee_reverse">
        <ul className="marquee_content">
          <li>
            <span>
              <img src={nh2} alt="" />
            </span>
          </li>
          <li>
            <span>
              <img src={nh1} alt="" />
            </span>
          </li>
          <li>
            <span>
              <img src={hana} alt="" />
            </span>
          </li>
          <li>
            <span>
              <img src={hanabank} alt="" />
            </span>
          </li>
          <li>
            <span>
              <img src={woori} alt="" />
            </span>
          </li>
          <li>
            <span>
              <img src={kb} alt="" />
            </span>
          </li>
          <li>
            <span>
              <img src={thecheat} alt="" />
            </span>
          </li>
          <li>
            <span>
              <img src={ahnlab} alt="" />
            </span>
          </li>
          <li>
            <span>
              <img src={est} alt="" />
            </span>
          </li>
        </ul>
        <ul className="marquee_content" aria-hidden="true">
          <li>
            <span>
              <img src={nh2} alt="" />
            </span>
          </li>
          <li>
            <span>
              <img src={nh1} alt="" />
            </span>
          </li>
          <li>
            <span>
              <img src={hana} alt="" />
            </span>
          </li>
          <li>
            <span>
              <img src={hanabank} alt="" />
            </span>
          </li>
          <li>
            <span>
              <img src={woori} alt="" />
            </span>
          </li>
          <li>
            <span>
              <img src={kb} alt="" />
            </span>
          </li>
          <li>
            <span>
              <img src={thecheat} alt="" />
            </span>
          </li>
          <li>
            <span>
              <img src={ahnlab} alt="" />
            </span>
          </li>
          <li>
            <span>
              <img src={est} alt="" />
            </span>
          </li>
        </ul>
      </div>
    </RollinWrap>
  )
}

export default RoolingPartner
