import styled from 'styled-components'
import STYLE_GUIDE from 'service/constant/color'
import paginationHoverBtn from 'assets/icon/pagination_hover.png'

export const PaginationContainer = styled.div`
  text-align: center;
  display: flex;
  // justify-content: center;
  button:first-child {
    margin-right: 10px;
  }
  button:nth-child(2) {
    margin-right: 30px;
  }

  @media screen and (max-width: 883px) {
    button:nth-child(2) {
      margin-right: 20px;
    }
  }
`

export const PaginationBtn = styled.button`
  padding: 0;
`

export const PreviousBtn = styled(PaginationBtn)`
  cursor: pointer;
  img {
    width: 40px;
    height: 40px;

    @media screen and (max-width: 883px) {
      width: 36px;
      height: 36px;
    }

    &:hover {
      z-index: 99;
      border: none;
      content: ${(props) => (props.currentPage !== 1 ? `url(${paginationHoverBtn})` : '')};
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`

export const NextBtn = styled(PaginationBtn)`
  cursor: pointer;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  img {
    width: 40px;

    @media screen and (max-width: 883px) {
      width: 36px;
      height: 36px;
    }

    &:hover {
      z-index: 99;
      border: none;
      content: ${(props) =>
        props.currentPage !== props.lastPage ? `url(${paginationHoverBtn})` : ''};
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`

export const IndexBtn = styled(PaginationBtn)`
  cursor: pointer;
  color: ${(props) => (props.active ? STYLE_GUIDE.COLOR.black1 : STYLE_GUIDE.COLOR.gray1)};
  font-weight: 700;
  font-size: 20px;
  margin-right: 20px;
  width: 12px;
  height: 36px;
  position: relative;

  &:hover {
    color: ${STYLE_GUIDE.COLOR.black1};
  }

  @media screen and (max-width: 883px) {
    width: 12px;
    height: 32px;
  }

  img {
    position: absolute;
    margin-top: 4px;
    display: ${(props) => (props.active ? 'block' : 'none')};
  }
`
