export const formatYmd = (date) => {
  const formatRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (!formatRegex.test(date)) {
    if(date.includes('T')){
      date = date.split('T')[0]
    }
    else{
      return ""
    }
  }
  return `${date.substr(2, 2)}.${date.substr(5, 2)}.${date.substr(8, 2)}`;
}

//신규 history reponse포멧을 구 history response
export const formatHistories = (data) => {
  const history = []
  let yearIndex = 0

  for(let i = 0; i < data.length; i++){
    const newHistory = {
      Year: "",
      histories: []
    }

    if(i === 0){
      newHistory.Year = data[i].Year
      newHistory.histories.push(data[i])
      history[yearIndex] = newHistory
      continue
    }

    if(i !== 0 && history[yearIndex].Year !== data[i].Year){
      yearIndex++
      newHistory.Year = data[i].Year
      newHistory.histories.push(data[i])
      history[yearIndex] = newHistory
    }
    else{
      history[yearIndex].histories.push(data[i])
    }
  }

  return history
}

export const formatStringToJSON = (data) => {
  if(typeof(data) === 'string' && typeof(data) !== 'object'){
    return JSON.parse(data)
  }
}