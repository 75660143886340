import styled from 'styled-components'
import Modal from 'react-modal'

// 모달 스타일 정의
export const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7 )', // 배경 딤 처리
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '20',
    outline: 'none',
  },
  // 모달 내용 스타일 정의
  content: {
    border: 'none',
    outline: 'none',
    // position: 'fixed',
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
  },
}

// 배경 딤 처리 스타일 적용
export const CustomModal = styled(Modal)`
  .ReactModal__Overlay {
    background-color: ${customStyles.overlay.backgroundColor};
  }

  .ReactModal__Content {
    border: none !important;
  }

  button {
    display: block;
    margin: 26px auto;
  }

  @media screen and (min-width: 1200px) {
    img {
      width: 430px;
    }
  }

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    img {
      width: 340px;
    }
  }

  @media screen and (max-width: 883px) and (min-width: 360px) {
    img {
      width: 260px;
    }
  }
`
