import styled, { css } from 'styled-components'
import STYLE_GUIDE from 'service/constant/color'

export const DefaultHeader = styled.header`
  z-index: 15;
  width: 100%;
  position: fixed;
  top: 0;

  > .container {
    display: flex;
    position: relative;
    align-items: center;
  }
`

export const HeaderWrap = styled(DefaultHeader)`
  transition: all 0.2s ease-in-out;

  ${(props) =>
    props.blurFlag &&
    css`
      background: rgba(255, 255, 255, 0.9);
    `}

  ${(props) =>
    props.blurFlag &&
    css`
      backdrop-filter: blur(5px);
    `}

  ${(props) =>
    props.blurCheck &&
    css`
      .main-menu {
        .menu {
          color: #252525;
        }
      }
    `}

  .logo {
    cursor: pointer;
  }

  .main-menu {
    > li {
      > a {
        font-size: 20px;
        font-weight: 500;
      }
    }

    .info_tab {
    }

    .service_tab {
    }
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    height: 0;
    background: ${STYLE_GUIDE.COLOR.white};
    border-top: 1px solid ${STYLE_GUIDE.COLOR.gray2};
    box-shadow: 0px 30px 36px rgba(0, 0, 0, 0.06);
    transition: all 0.2s ease;
    opacity: 0;
  }

  &.on {
    background: ${STYLE_GUIDE.COLOR.white};
    transition: all 0.1s ease;
    &:after {
      opacity: 1;
      height: 360px;
    }
  }

  .depth2.on {
    display: block;
  }

  .depth2 {
    display: none;
    position: absolute;
    top: 100px;
    z-index: 5;
  }
`

export const MobileHeaderWrap = styled(DefaultHeader)`
  .logo {
    width: 135px;
    cursor: pointer;
  }

  .m_header {
    ${(props) =>
      props.blurFlag &&
      !props.showMobileMenu &&
      css`
        background: rgba(255, 255, 255, 0.9);
      `}

    ${(props) =>
      props.blurFlag &&
      !props.showMobileMenu &&
      css`
        backdrop-filter: blur(1px);
      `}
    display: flex;
    justify-content: space-between;
    position: fixed;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .logo_area {
      margin: 21px 0 21px 60px;
    }

    .menu_area {
      margin: 15px 60px 15px 0;
    }
  }

  @media screen and (max-width: 883px) {
    .m_header {
      .logo_area {
        margin: 21px 0 21px 20px;
      }

      .menu_area {
        margin: 15px 20px 15px 0;
      }
    }
  }
`

export const MobileMenu = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  padding-top: 110px;
  background: ${STYLE_GUIDE.COLOR.black1};

  .container .menu_list {
    display: flex;
    align-items: center;
    height: 60px;
    padding-bottom: 10px;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: ${STYLE_GUIDE.COLOR.white};

    img {
      width: 8px;
      padding-right: 10px;
    }
  }

  .menu_link {
    color: ${STYLE_GUIDE.COLOR.white};
  }
`
