import { DropdownMenu, FooterContent, FooterWrap } from 'styles/views/Footer/Footer'
import { useEffect, useRef, useState } from 'react'

import { Link } from 'react-router-dom'
import dropdownArrow from 'assets/icon/ico_footer-arrow_18.png'
import enLogoB from 'assets/icon/logo/img_footer-logo_260.png'
import { useMediaQuery } from 'react-responsive'

const Footer = () => {
  const outsideRef = useRef()

  const isMobile = useMediaQuery({
    query: '(max-width:884px)',
  })

  const menu = [
    { key: 'option_1', label: '스마트피싱보호', link: 'https://www.antiscam.co.kr/' },
    { key: 'option_2', label: '오토콜', link: 'https://www.autocall.ai/' },
    { key: 'option_3', label: '휴대폰분실보호', link: 'https://www.mfinder.co.kr/home' },
    { key: 'option_4', label: '휴대폰분실보호', subLabel:'Global', link: 'https://www.mfinder.ai/ ' },
    { key: 'option_5', label: '휴대폰가족보호', link: 'https://www.familycare.ai/' },
    { key: 'option_6', label: '휴대폰쿠폰지갑', link: 'https://www.couponwallet.co.kr/ ' },
  ]

  const dropdownItem = useRef()
  const dropdownList = useRef()
  const [arrow, setArrow] = useState(false)

  const handleClick = () => {
    if (dropdownItem.current.classList.contains('on')) {

      dropdownItem.current.classList.remove('on')
      dropdownList.current.classList.remove('on')
      // setArrow(false)
    } else {

      dropdownItem.current.classList.add('on')
      dropdownList.current.classList.add('on')
      // setArrow(true)
    }
  }

  useEffect(() => {
    function handleClickOutside(event) {
      // 현재 document에서 mousedown 이벤트가 동작하면 호출되는 함수입니다.
      if (dropdownList.current && !dropdownList.current.contains(event.target)) {
        if (dropdownItem.current.classList.contains('on')) {
          dropdownItem.current.classList.remove('on')
          dropdownList.current.classList.remove('on')
        }
      }
    }
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [dropdownList])

  return (
    <FooterWrap ref={outsideRef}>
      <FooterContent className="container">
        <div className="logo_area">
          <img src={enLogoB} alt={'로고'} />
        </div>
        <div className="info_area">
          <div className="address_area">
            <address>
              <p>
                {isMobile ? '' : <span>&nbsp;&middot;</span>}주소  :  서울특별시 영등포구 국제금융로 2길 32 7층{isMobile ? <br/> : <span>&nbsp;</span>}<span>(대표 : 강원석)</span>
              </p>
              <div className={'sub_info'}>
                <span className="copr_num">
                  {isMobile ? '' : <span>&nbsp;&middot;</span>}사업자등록번호 : 382-86-01230
                </span>
                <span className="copr_num">
                  {isMobile ? '' : <span>&nbsp;&middot;</span>}통신판매업 신고번호 : 제2020-서울영등포-1315호
                </span>
              </div>
              <div className={'svc_number'}>
                <span>{isMobile ? '' : <span>&nbsp;&middot;</span>}고객센터  :</span>
                <ul>
                  <li><a href="tel:1811-7531" title="스마트피싱보호 전화연결">1811-7531</a><span>(스마트피싱보호)</span></li>
                  <li><a href="tel:1811-4031" title="휴대폰분실보호 전화연결">1811-4031</a><span>(휴대폰분실보호)</span></li>
                  <li><a href="tel:1566-7531" title="오토콜 전화연결">1566-7531</a><span>(오토콜)</span></li>
                  <li><a href="tel:1855-3631" title="휴대폰가족보호 전화연결">1855-3631</a><span>(휴대폰가족보호)</span></li>
                  <li><a href="tel:1877-7331" title="이벤트U 전화연결">1877-7331</a><span>(이벤트 U)</span></li>
                </ul>
              </div>
              {/*<a className="tel_number" href="tel:02-6013-0625">
                {isMobile ? '' : <span>&middot;</span>} Tel : 02-6013-0625
              </a>
              <span className="fax_number">
                {isMobile ? '' : <span>&middot;</span>} Fax : 02-6105-6396
              </span>*/}

            </address>
            <div className="copy_wrap">
              <span className="copyright">&copy; 2023 DataUniverse All rights reserved.</span>
            </div>
          </div>
          <div className="corp_area">
            <ul className="corp_list">
              <li className="corp_item">
                <Link to={'information'} state={{ state: 1 }}>
                  회사 소개
                </Link>
              </li>
              <li className="corp_item">
                <Link to={'information'} state={{ state: 6 }}>
                  제휴/문의
                </Link>
              </li>
              <li className="corp_item dropdown_list" ref={dropdownList}>
                <DropdownMenu
                  className="dropdown_menu"
                  ref={dropdownItem}
                  onClick={handleClick}
                  arrow={arrow}
                >
                  <button className="btn_service">
                    서비스
                    {<img src={dropdownArrow} alt="arrow" />}
                  </button>
                  <ul className="service_depth">
                    {menu.map((item) => (
                      <li key={item.key}>
                        <a href={item.link} target={'_blank'} rel="noreferrer">
                          {item.label}
                          <span> {item.subLabel}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </DropdownMenu>
              </li>
            </ul>
          </div>
        </div>
      </FooterContent>
    </FooterWrap>
  )
}
export default Footer
