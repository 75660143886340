import Pagination from 'component/common/Pagination'
import STYLE_GUIDE from 'service/constant/color'
import styled from 'styled-components'

import post_img1 from 'assets/images/post/게시_전자증권전환대상주권등의권리자보호를위한공고.pdf'
import { useCallback, useEffect, useState } from 'react'
import { getPatentAndNewsList, getTotalNum } from 'service/fetchs'
import { formatYmd } from 'service/utils/format'
import TopButton from "../component/common/TopButton"

export const NewsWrap = styled.div`
  &.news_section {
    padding: 220px 0 0 0;
  }

  .section_title {
    padding: 0 0 30px 0;
    font-weight: 700;
    font-size: 56px;
    line-height: 72px;
    color: ${STYLE_GUIDE.COLOR.black1};
  }
  .item_wrap {
    display: flex;
    justify-content: space-between;
    padding: 50px 0;

    .sub_title {
      // padding: 0 40px 0 0;
      width: 233px;
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      color: ${STYLE_GUIDE.COLOR.black1};
    }

    .news_list_wrap {
      .news_list {
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
        color: ${STYLE_GUIDE.COLOR.black1};
        width: 1327px;
        padding: 0 0 40px 0;

        > li {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        > li:not(:last-child) {
          padding: 0 0 20px;
        }

        .news_title_link {
          display: flex;
          align-items: center;

          .news_title {
            display: inline-block;
            max-width: 1185px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .news_title.new {
            max-width: 1110px;
          }

          .news_title:hover {
            cursor: pointer;
          }
        }

        .news_reg_dt {
          flex: 0 0  auto;
          display: inline-block;
          font-weight: 400;
          font-size: 24px;
          line-height: 26px;
          text-align: right;
          color: #999999;
        }
      }
      .pagination {
        padding: 40px 0 0;
      }
    }

    .main_img
    {
      img {
        border-radius: 30px;
      }
    }

    .sub_img {
      display: flex;
      justify-content: space-between;
      padding: 40px 0 0 0;

      img {
        cursor: pointer;
        //border: 1px solid #dbdbdb;
      }
    }
  }

  @media screen and (max-width: 1919px) and (min-width: 1200px) {
    &.news_section {
      padding: 200px 0 0 0;
    }

    .item_wrap {
      .news_list_wrap {
        width: 742px;

        .news_list {
          width: 100%;
          padding: 0 0 40px 0;

          .news_title_link {
            .news_title {
              max-width: 615px;
            }

            .news_title.new {
              max-width: 545px;
            }
          }
        }
      }

      .main_img,
      .sub_img {
        img {
          //border-radius: 20px;
        }
      }

      .sub_img {
        padding: 30px 0 0 0;

        img {
          width: 234px;
        }
      }
    }
  }

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    &.news_section {
      padding: 140px 0 0 0;
    }

    .section_title {
      font-size: 36px;
      line-height: 48px;
      padding: 0px 0px 16px;
    }

    .item_wrap {
      display: block;
      padding: 30px 0 70px;

      .sub_title {
        font-size: 28px;
        line-height: 40px;
        padding: 0 0 20px 0;
      }

      .news_list_wrap {
        .news_list {
          width: 100%;
          font-size: 16px;
          line-height: 30px;
          padding: 0 0 30px 0;

          > li:not(:last-child) {
            padding: 0 0 14px 0;
          }

          > li {
            display: block;
          }

          .news_title_link {
            .news_title {
              max-width: 745px;
            }

            .news_title.new {
              max-width: 675px;
            }
          }

          .news_reg_dt {
            display: block;
            text-align: left;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }

      .top.news_list_wrap {
        display: block;
      }

      .news_list_wrap {
        display: flex;
        justify-content: space-between;
      }

      .main_img{
        img {
          //border-radius: 30px;
        }
      }

      .sub_img {
        flex-direction: column;
        padding: 0;
      }
    }
  }

  @media screen and (max-width: 883px) {
    padding: 140px 0 0 0;

    .section_title {
      font-size: 36px;
      line-height: 48px;
      padding: 0 0 16px;
    }

    .item_wrap {
      padding: 30px 0 70px 0;
      display: block;

      .sub_title {
        font-size: 28px;
        line-height: 40px;
      }

      .news_list_wrap {
        padding: 20px 0 0;

        .news_list {
          width: 100%;
          padding: 0 0 30px;

          > li:not(:last-child) {
            padding: 0px 0px 14px;
          }

          > li {
            display: block;
            align-items: center;
          }


          .news_title_link {
            .news_title {
              font-size: 16px;
              line-height: 30px;
              max-width: 310px;
            }

            .news_title.new {
              max-width: 250px;
            }
          }

          .news_reg_dt {
            display: block;
            text-align: left;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
        img {
          //border-radius: 30px;
        }
      .sub_img {
        display: block;
        padding: 30px 0 0;

        figure:first-child {
          padding: 0 0 20px;
        }
      }
    }
  }
`

export const NewTag = styled.span`
  display: inline-block;
  margin-left: 20px;
  border: 2px solid #DBDBDB;
  outline-color: #DBDBDB;
  color: #999999;
  padding: 0 10px;
  border-radius: 20px;
  line-height: 26px;
  font-size: 14px;

  &:before {
    content: "NEW";
  }

  @media screen and (max-width: 1199px) {
    margin-left: 12px;
  }
`

const News = () => {
  const NEWS_PAGE_SIZE = 15
  const [newsList, setNewsList] = useState([])
  const [pageNum, setPageNum] = useState(1)
  const [totalNum, setTotalNum] = useState()

  const getNews = useCallback(async () => {
    const newsData = await getPatentAndNewsList('NEWS', 0, (pageNum - 1) * NEWS_PAGE_SIZE, NEWS_PAGE_SIZE)
    setNewsList(newsData)

    const num = await getTotalNum('NEWS_CNT')
    setTotalNum(num.TotalData)
  }, [pageNum])

  const onClickOpenImagePopup = (img) => {
    window.open(`${img}`)
  }

  useEffect(() => {
    getNews(pageNum)
  }, [getNews, pageNum])

  return (
    <div className="container">
      <NewsWrap className="news_section">
        <h2 className="section_title">
          데이터유니버스의
          <br />
          모든 소식을 확인해 보세요.
        </h2>
        <hr className="section_bar" />
        <div className="item_wrap">
          <h3 className="sub_title">뉴스룸</h3>
          <div className="top news_list_wrap">
            <ul className="news_list">
              {newsList.map((item, index) => {
                const isNew = pageNum === 1 && index === 0;
                return (
                  <li key={item.Idx}>
                    <a href={item.LinkPath} target="_blank" rel="noreferrer" className="news_title_link">
                      <span className={(isNew ? "new" : "") + " news_title"}>
                        {item.Title}
                      </span>
                      {isNew && <NewTag></NewTag>}
                    </a>
                    <span className="news_reg_dt">{formatYmd(item.NewsDate)}</span>
                  </li>
                )}
              )}
            </ul>
            {totalNum > NEWS_PAGE_SIZE && (
              <Pagination
                className="pagination"
                totalNum={totalNum}
                setPageNum={setPageNum}
                divisionNum={NEWS_PAGE_SIZE}
              />
            )}
          </div>
        </div>
        <div className="item_wrap">
          <h3 className="sub_title">게시</h3>
          <div className="top news_list_wrap">
            <ul className="news_list">
              <li>
                <div className="news_title_link" onClick={() => onClickOpenImagePopup(post_img1)}>
                  <span className="news_title">
                    전자증권 전환 대상 주권 권리자(주주) 보호 및 조치사항 안내
                  </span>
                </div>
                <span className="news_reg_dt">24.11.06</span>
              </li>
            </ul>
          </div>
        </div>
      </NewsWrap>
      <TopButton/>
    </div>
  )
}

export default News
