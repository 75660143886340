import { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { useRecoilValue } from 'recoil'
import { headerBlurFlag } from 'store/index'
import { HeaderWrap, MobileHeaderWrap, MobileMenu } from 'styles/views/Header/Header'
import { MenuWrap } from 'styles/views/Header/Menu'
import whiteLogo from 'assets/icon/logo/[PC]logo_du_white_200.png'
import defaultLogo from 'assets/icon/logo/[PC]logo_du_original_200.png'
import defaultMenu from 'assets/icon/menu/img_du_menu-4.png'
import whiteMenu from 'assets/icon/menu/img_du_menu-3.png'
import clickMenu from 'assets/icon/menu/img_du_menu-5.png'
import ellipseIcon from 'assets/icon/menu/Ellipse 57.png'

const Header = () => {
  const [menuFlag, setMenuFlag] = useState(false)
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [hoveredItem, setHoveredItem] = useState(null)
  const [blurFlag, setBlurFlag] = useState()

  const { pathname } = useLocation()
  const blurCheck = useRecoilValue(headerBlurFlag)

  const menuList = [
    { num: 1, name: '데이터유니버스', link: 'information' },
    { num: 2, name: '서비스', link: 'service' },
    { num: 3, name: '뉴스', link: 'news' },
    { num: 4, name: '채용안내', link: 'recruit' },
  ]

  const isMobile = useMediaQuery({
    query: '(max-width:1199px)',
  })

  const onClick = () => {
    setShowMobileMenu(!showMobileMenu)
  }

  const handleMouseEnter = (item) => {
    setHoveredItem(item)
  }

  const handleMouseLeave = () => {
    setHoveredItem(null)
  }


  const menuClick = () => {
    const header = document.querySelector('.header')
    const depth2 = document.querySelectorAll('.depth2')
    header?.classList.remove('on')
    depth2.forEach((ele) => ele.classList.remove('on'))
  }

  useEffect(() => {
    const header = document.querySelector('.header')
    // const mainMenu = document.querySelector('.main-menu')
    const depth2 = document.querySelectorAll('.depth2')

    // mainMenu?.addEventListener('mouseenter', (event) => {
    //   header?.classList.add('on')
    //   depth2.forEach((ele) => ele.classList.add('on'))
    //   setMenuFlag(true)
    // })

    const test = document.querySelectorAll('.menu')

    Array.prototype.forEach.call(test, function (e) {
      e?.addEventListener('mouseenter', (event) => {
        header?.classList.add('on')
        depth2.forEach((ele) => ele.classList.add('on'))
        setMenuFlag(true)
      })
    })

    header?.addEventListener('mouseleave', (event) => {
      header?.classList.remove('on')
      depth2.forEach((ele) => ele.classList.remove('on'))
      setMenuFlag(false)
    })
  }, [menuFlag, isMobile])

  // const scrollHandler = (e) => {

  //   const scroll = document.querySelector('header').clientTop


  useEffect(() => {
    if (pathname !== '/') {
      setBlurFlag(true)
    } else if (pathname === '/' && blurCheck) {
      setBlurFlag(true)
    } else {
      setBlurFlag(false)
    }
  })

  useEffect(() => {
    setBlurFlag(blurCheck)
  }, [blurCheck])

  useEffect(() => {
    menuList.forEach((d) => {
      if (pathname.indexOf(d.link) > -1) {
        setHoveredItem(d.num)
      }
    })


    const menu = document.querySelectorAll('.menu')
    // document.querySelectorAll('.menu').
    Array.prototype.forEach.call(menu, function (e) {
      e.classList.remove('active')
      if (pathname === '/information' && e.innerText === '데이터유니버스') {
        e.classList.add('active')
      }
      if (pathname === '/service' && e.innerText === '서비스') {
        e.classList.add('active')
      }
      if (pathname === '/news' && e.innerText === '뉴스') {
        e.classList.add('active')
      }
      if (pathname === '/recruit' && e.innerText === '채용안내') {
        e.classList.add('active')
      }
    })
    // if(pathname === '/information') {

    // }
  }, [pathname])

  const logoClick = () => {
    const path = window.location.pathname
    if (path === '/') {
      window.location.reload()
    }
  }

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    showMobileMenu ? (
      body.classList.add('scroll_lock')
    ) : (
        body.classList.remove('scroll_lock')
    )
  }, [showMobileMenu])


  return (
    <>
      {isMobile ? (
        <MobileHeaderWrap
          pathname={pathname}
          blurFlag={blurFlag}
          showMobileMenu={showMobileMenu}
          className="header"
        >
          {showMobileMenu && (
            <MobileMenu>
              <div className="container">
                <ul>
                  {menuList.map((item, index) => (
                    <Link to={item.link} onClick={onClick} key={index}>
                      <li
                        key={item.num}
                        onMouseEnter={() => handleMouseEnter(item.num)}
                        onMouseLeave={() => handleMouseLeave()}
                        className="menu_list"
                      >
                        {hoveredItem === item.num ? (
                          <img
                            src={ellipseIcon}
                            style={{ visibility: 'visible' }}
                            alt={item.name}
                          />
                        ) : (
                          <img src={ellipseIcon} style={{ visibility: 'hidden' }} alt={item.name} />
                        )}
                        <div className="menu_link">{item.name}</div>
                      </li>
                    </Link>
                  ))}
                </ul>
              </div>
            </MobileMenu>
          )}
          <div className="m_header">
            <h1 className="logo_area">
              {(pathname !== '/' && !showMobileMenu) || (blurFlag && !showMobileMenu) ? (
                <Link
                  to="/"
                  onClick={() => {
                    setShowMobileMenu(false)
                    handleMouseLeave()
                    logoClick()
                  }}
                >
                  <img className="logo" src={defaultLogo} alt={'로고'} />
                </Link>
              ) : (
                <Link
                  to="/"
                  onClick={() => {
                    setShowMobileMenu(false)
                    handleMouseLeave()
                    logoClick()
                  }}
                >
                  <img className="logo" src={whiteLogo} alt={'로고'} />
                </Link>
              )}
            </h1>
            <MenuWrap className="menu_area">
              {pathname !== '/' && !showMobileMenu && (
                <button
                  className="hambuger_btn"
                  onClick={onClick}
                  style={{
                    backgroundImage: `url(${defaultMenu})`,
                    width: '26px',
                    height: '26px',
                  }}
                ></button>
              )}
              {pathname === '/' && !showMobileMenu && !blurFlag && (
                <button
                  className="hambuger_btn"
                  onClick={onClick}
                  style={{
                    backgroundImage: `url(${whiteMenu})`,
                    width: '26px',
                    height: '26px',
                  }}
                ></button>
              )}
              {pathname === '/' && !showMobileMenu && blurFlag && (
                <button
                  className="hambuger_btn"
                  onClick={onClick}
                  style={{
                    backgroundImage: `url(${defaultMenu})`,
                    width: '26px',
                    height: '26px',
                  }}
                ></button>
              )}
              {showMobileMenu && (
                <button
                  className="hambuger_btn"
                  onClick={onClick}
                  style={{
                    backgroundImage: `url(${clickMenu})`,
                    width: '26px',
                    height: '26px',
                  }}
                ></button>
              )}
            </MenuWrap>
          </div>
        </MobileHeaderWrap>
      ) : (
        <HeaderWrap
          className="header"
          menuFlag={menuFlag}
          pathname={pathname}
          blurFlag={blurFlag}
          blurCheck={blurCheck}
        >
          <div className="container">
            <h1>
              {menuFlag || pathname !== '/' || blurCheck ? (
                <Link
                  to="/"
                  onClick={() => {
                    logoClick()
                  }}
                >
                  <img className="logo" src={defaultLogo} alt="datauniverse" />
                </Link>
              ) : (
                <Link
                  to="/"
                  onClick={() => {
                    logoClick()
                  }}
                >
                  <img className="logo" src={whiteLogo} alt="datauniverse" />
                </Link>
              )}
            </h1>
            <MenuWrap menuFlag={menuFlag} pathname={pathname}>
              <ul className="main-menu">
                <li className="info_tab">
                  <Link to="information" className="menu" onClick={() => menuClick()}>
                    데이터유니버스
                  </Link>
                  <ul className="depth2">
                    <li onClick={() => menuClick()}>
                      <Link to={'information'} state={{ state: 1 }}>
                        회사소개
                      </Link>
                    </li>
                    <li onClick={() => menuClick()}>
                      <Link to={'information'} state={{ state: 2 }}>
                        특허 및 인증
                      </Link>
                    </li>
                    <li onClick={() => menuClick()}>
                      <Link to={'information'} state={{ state: 3 }}>
                        히스토리
                      </Link>
                    </li>
                    <li onClick={() => menuClick()}>
                      <Link to={'information'} state={{ state: 4 }}>
                        파트너
                      </Link>
                    </li>
                    <li onClick={() => menuClick()}>
                      <Link to={'information'} state={{ state: 5 }}>
                        CI
                      </Link>
                    </li>
                    <li onClick={() => menuClick()}>
                      <Link to={'information'} state={{ state: 6 }}>
                        Contact us
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="service_tab">
                  <Link to="service" className="menu" onClick={() => menuClick()}>
                    서비스
                  </Link>
                  <ul className="depth2">
                    <li onClick={() => menuClick()}>
                      <Link to="service">서비스소개</Link>
                    </li>
                  </ul>
                </li>
                <li className="news_tab">
                  <Link to="news" className="menu" onClick={() => menuClick()}>
                    뉴스
                  </Link>
                  <ul className="depth2">
                    <li onClick={() => menuClick()}>
                      <Link to="news">뉴스</Link>
                    </li>
                  </ul>
                </li>
                <li className="recurit_tab">
                  <Link to="recruit" className="menu" onClick={() => menuClick()}>
                    채용안내
                  </Link>
                  <ul className="depth2">
                    <li onClick={() => menuClick()}>
                      <Link to="recruit" state={{ state: 1 }}>
                        DU와 함께해요
                      </Link>
                    </li>
                    <li onClick={() => menuClick()}>
                      <Link to="recruit" state={{ state: 2 }}>
                        채용 공고
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </MenuWrap>
          </div>
        </HeaderWrap>
      )}
    </>
  )
}

export default Header
