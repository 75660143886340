import axios from 'axios'
import { ERROR_CODE, URL } from 'service/constant/common'

/**
 * 서버와 통신하는 api 함수.
 */
export const axiosPostData = async (httpRequest) => {
  try{
    const result = await axios.post(hostServer(), httpRequest)
    //현재 string으로 내려오기 때문에 임시적으로 JSON으로 파싱
    // const parsed = formatStringToJSON(result.data)
    return errorHandler(result.data)
  }catch(error){
    console.error(error)
  }
}

/**
 * 요청 상세주소를 입력하면 현재 실행중인 클라이언트의 도메인에 따라 전체 요청 URL을 반환하는 함수
 * @returns {string} host를 포함한 전체 URL을 반환
 */
export const hostServer = () => {
  const host = window.location.hostname

  return host.includes('localhost') || host.includes('dev') ? URL.DEV.REST : URL.PRO.REST
}

/**
 * Error Handler
 */
export const errorHandler = (response) => {
  const Header = response["Header"]

  switch (Header['ErrCode']){
    case ERROR_CODE.SUCCESS:
      return response['Body']
    case ERROR_CODE.ERROR_UNKNOWN_CMDTYPE:
      console.error('err: ' + Header['ErrMsg'])
      break
    default:
      console.warn('title: ' + Header['ErrCode'])
  }
}