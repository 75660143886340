import 'swiper/css'
import 'swiper/css/pagination'

import {
  BannerWrap,
  MoreButton,
  SectionWrap1,
  SectionWrap2,
  SectionWrap3,
  SectionWrap4,
  SectionWrap5,
  SectionWrap6,
  SloganWrap,
  SwiperWrap,
} from 'styles/views/Information'
import { Swiper, SwiperSlide } from 'swiper/react'
import { getHistoryList, getPatentAndNewsList, getTotalNum } from 'service/fetchs'
import { useCallback, useEffect, useRef, useState } from 'react'

import JSZip from 'jszip'
import { ReactComponent as Loader } from 'assets/icon/loading-spinner.svg'
import Logo from 'assets/ci/로고.png'
import Logo_Kor from 'assets/ci/혼합형로고타입.png'
import Lottie from 'lottie-react'
import { MainButton3 } from 'styles/common/button'
import MapComponent from 'component/MapComponent'
import { Mousewheel } from 'swiper'
import Pagination from 'component/common/Pagination'
import PatentModal from 'component/common/PatentModal'
import RollingPartner from 'component/RollingPartner'
import Symbol from 'assets/ci/Symbol.png'
import TopButton from '../component/common/TopButton'
import VideoComponent from '../component/VideoComponent'
import antiScamIcon from 'assets/icon/service/ico_antiscam_50.png'
import arrow from 'assets/json/du_arrow.json'
import autocallIcon from 'assets/icon/service/ico_autocall_50.png'
import cert from 'assets/images/info/img_2022-t3_1327.png'
import certHc from 'assets/images/info/img_certification_03.svg'
import certInnobiz from 'assets/images/info/img_certification_02.svg'
import certKoita from 'assets/images/info/img_certification_01.svg'
import ci from 'assets/images/info/img_ci-color_450.png'
import couponwalletIcon from 'assets/icon/service/ico_couponwallet_50.png'
import du1 from 'assets/images/info/img_company_web01_1327.png'
import du2 from 'assets/images/info/img_company_web02_1327.png'
import du3 from 'assets/images/info/img_company_web03_1327.png'
import duVision from 'assets/video/DU_vison_bg.mp4'
import eventuIcon from 'assets/icon/service/ico_eventu_50.png'
import familycareIcon from 'assets/icon/service/ico_familycare_50.png'
import { formatYmd } from 'service/utils/format'
import gsap from 'gsap'
import mfinderIcon from 'assets/icon/service/ico_mfinder_50.png'
import miniDu1 from 'assets/images/info/img_company_web01.png'
import miniDu2 from 'assets/images/info/img_company_web02.png'
import miniDu3 from 'assets/images/info/img_company_web03.png'
import mobileCert from 'assets/images/info/img_cert-mob.svg'
import mobileDu1 from 'assets/images/info/img_company_web01_764.png'
import mobileDu2 from 'assets/images/info/img_company_web02_764.png'
import mobileDu3 from 'assets/images/info/img_company_web03_764.png'
import mobileHc from 'assets/images/info/img_certification_03-mob.svg'
import mobileInnobiz from 'assets/images/info/img_certification_02-mob.svg'
import mobileKoita from 'assets/images/info/img_certification_01-mob.svg'
import mobileServiceFront from 'assets/json/mobile-duservice-front.json'
import serviceFront from 'assets/json/duservice-front.json'
import symbol from 'assets/images/info/img_ci-logo_450.png'
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

const Information = () => {
  const PATENT_PAGE_SIZE = 10

  const { state } = useLocation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [patentsImg, setPatentsImg] = useState()
  const [patentList, setPatentList] = useState([])
  const [patentTotalNum, setPatentTotalNum] = useState()
  const [patentPageNum, setPatentPageNum] = useState(0)
  const [historyList, setHistoryList] = useState([])
  const [hasMoreHistory, setHasMoreHistory] = useState()
  const [isLoadingHistory, setLoadingHistory] = useState(false)
  const [historyPageNum, setHistoryPageNum] = useState(1)
  const [touchHeight, setTouchHeight] = useState(0)
  const [swiperIndex, setSwiperIndex] = useState(0)
  const sectionRef = useRef([])
  const [sloganFlag, setSloganFlag] = useState(true)
  const swiperRef = useRef()
  const [imgType, setImgType] = useState()

  const isPc = useMediaQuery({
    query: '(min-width:1200px)',
  })
  const isLaptop = useMediaQuery({
    query: '(max-width:1919px) and (min-width:1200px)',
  })
  const isTablet = useMediaQuery({
    query: '(min-width:884px) and (max-width:1199px)',
  })
  const isPhone = useMediaQuery({
    query: '(max-width:883px)',
  })
  const isMobile = useMediaQuery({
    query: '(max-width:1199px)',
  })

  useEffect(() => {
    setTimeout(() => {
      if (state?.state) {
        const section = document.querySelectorAll('.section')
        let sections = {}
        Array.prototype.forEach.call(section, function (e) {
          sections[e.id] = e.offsetTop - document.querySelector('.header')?.offsetHeight
        })
        if (state.state === 1) {
          window.scrollTo({ top: 0, behavior: 'smooth' })
          swiperRef.current.swiper.enable()
          swiperRef.current.swiper.slideTo(0)
        } else {
          window.scrollTo({ top: sections['section' + state.state], behavior: 'smooth' })
        }
        state.state = null
        window.history.replaceState({}, document.title)
      }
    }, 400)
  }, [state?.state])

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const enableSwiper = () => {
    document.getElementsByClassName('swiper-vertical')[0].style.touchAction = 'pan-x'
    swiperRef.current.swiper?.enable()
  }

  const disableSwiper = () => {
    document.getElementsByClassName('swiper-vertical')[0].style.touchAction = 'pan-y'
    swiperRef.current.swiper?.disable()
  }

  const elementRef = useRef(null)
  // const scrollHandler = (e) => {
  //   let st = window.scrollY || document.documentElement.scrollTop
  //   if (e.type === 'wheel') {
  //     if (st === 0 && !swiperRef.current.swiper?.enabled && e.deltaY < 0) {
  //       enableSwiper()
  //     }
  //     return
  //   }
  //   if (st !== 0 && swiperRef.current.swiper?.enabled) {
  //     disableSwiper()
  //   }
  //
  // }
  const scrollHandler = (e) => {
    let st = window.scrollY || document.documentElement.scrollTop
    const swiperEnabled = swiperRef.current.swiper?.enabled

    if (e.type === 'wheel') {
      if (st === 0 && !swiperEnabled && e.deltaY < 0) {
        disableSwiper()
      }
      // if (st === 0 && !swiperRef.current.swiper?.enabled && e.deltaY < 0) {
      //       enableSwiper()
      //     }
      //     return
    }
    // if (st === 0 && !swiperRef.current.swiper.enabled) {
    //     enableSwiper()
    //   }
    //   // } else {
    //   //   /*console.warn(`scrollHandler: st: ${st}, enabled: ${swiperRef.current.swiper.enabled}` )
    //   //   disableSwiper()*/
    //   // }

    if (st !== 0 && swiperEnabled) {
      disableSwiper()
    }
  }

  const getPatents = useCallback(async () => {
    const patentData = await getPatentAndNewsList('PATENTS', 0, patentPageNum, PATENT_PAGE_SIZE)
    setPatentList(patentData)

    const num = await getTotalNum('PATENTS_CNT')
    setPatentTotalNum(num.totalData)
  }, [patentPageNum, setPatentList, setPatentTotalNum])

  const getHistories = useCallback(async () => {
    if (hasMoreHistory) {
      const historyData = await getHistoryList('HISTORIES', historyPageNum)

      setHistoryList((h) => [
        ...h,
        ...historyData.filter((yearHistory) =>
          h.every((orgYearHistory) => orgYearHistory.Year !== yearHistory.Year),
        ),
      ])
    }
  }, [historyPageNum, hasMoreHistory, setHistoryList])

  const getHistoryCount = useCallback(async () => {
    const num = await getTotalNum('HISTORIES_CNT')
    setHasMoreHistory(num.TotalData > historyList.flatMap((obj) => obj.histories).length)
    setLoadingHistory(false)
  }, [historyList, setHasMoreHistory])

  const nextHistoryPage = () => {
    setLoadingHistory(true)
    setHistoryPageNum(historyPageNum + 1)
  }

  const ciDownload = () => {
    const image = [
      { img: Logo, name: 'Logo' },
      { img: Symbol, name: 'Symbol' },
      { img: Logo_Kor, name: 'Logo_Kor' },
    ]

    Promise.all(image.map((url) => fetch(url.img).then((response) => response.blob()))).then(
      async (blobs) => {
        const zip = new JSZip()

        blobs.forEach((d, i) => {
          zip.file(image[i].name + '.png', d)
        })

        zip.generateAsync({ type: 'blob' }).then(function (blob) {
          const url = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'DataUniverse CI.zip')
          document.body.appendChild(link)
          link.click()
          link.parentNode.removeChild(link)
        })
      },
    )
  }

  const serviceStyle = {
    width: isLaptop ? '920px' : '1400px',
  }

  function ani() {
    const fadeinEls = document.querySelectorAll('.caption')
    fadeinEls.forEach(function (textanimation, index) {
      gsap.fromTo(
        textanimation,
        { opacity: 0, y: 50 },
        { opacity: 1, y: 0, duration: 1, delay: index === 0 ? 0 : (index + 1) * 0.2 },
      )
    })
  }

  let startTouchY = 0
  let endTouchY = 0

  const touchStartHandler = (e) => {
    startTouchY = e.changedTouches[0].clientY
  }

  const touchEndHandler = (e) => {
    endTouchY = e.changedTouches[0].clientY
    setTouchHeight(Math.trunc(startTouchY) - Math.trunc(endTouchY))

    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  const popIconClick = () => {
    document.getElementById('section1').scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler)
    window.addEventListener('wheel', scrollHandler)
    window.addEventListener('touchstart', touchStartHandler)
    window.addEventListener('touchend', touchEndHandler)
    return () => {
      window.removeEventListener('scroll', scrollHandler)
      window.removeEventListener('wheel', scrollHandler)
      window.removeEventListener('touchstart', touchStartHandler)
      window.removeEventListener('touchend', touchEndHandler)
    }
  }, [])

  useEffect(() => {
    getPatents()
  }, [patentPageNum, getPatents])

  useEffect(() => {
    getHistories()
  }, [historyPageNum, getHistories])

  useEffect(() => {
    getHistoryCount()
  }, [historyList, getHistoryCount])

  useEffect(() => {
    if (touchHeight > 0 && swiperRef.current.swiper.enabled && swiperRef.current.swiper.isEnd) {
      disableSwiper()

      // window.scrollBy({
      //   top: touchHeight,
      //   left: 0,
      //   behavior: "smooth"
      // })
    } else {
      enableSwiper()
    }
  }, [touchHeight])

  const duLottieRef = useRef()
  useEffect(() => {
    switch (swiperIndex) {
      case 0:
        setTimeout(() => {
          duLottieRef.current.goToAndPlay(0, true)
        }, 1000)
        break
      case 1:
        if (sloganFlag) {
          ani()
          setSloganFlag(false)
        }
        break
      default:
    }
  }, [swiperIndex])

  return (
    <>
      <SwiperWrap>
        <Swiper
          ref={swiperRef}
          direction={'vertical'}
          slidesPerView={1}
          mousewheel={{
            forceToAxis: true,
            sensitivity: 1,
            // releaseOnEdges: true,
          }}
          speed={1000}
          touchReleaseOnEdges={true}
          allowTouchMove={true}
          modules={[Mousewheel]}
          onSlideChange={(swiper) => {
            setTimeout(function () {
              setSwiperIndex(swiper.activeIndex)
            }, 1000)
          }}
          onReachEnd={(swiper) => {
            setTimeout(function () {
              disableSwiper()
            }, 1000)
          }}
          className="mySwiper"
        >
          <SwiperSlide>
            <BannerWrap>
              <div className="banner_wrap container">
                <div className="video_wrap">
                  <VideoComponent />
                  {isMobile ? (
                    <Lottie
                      lottieRef={duLottieRef}
                      loop={false}
                      autoplay={false}
                      animationData={mobileServiceFront}
                      className="lottie_service"
                    />
                  ) : (
                    <Lottie
                      lottieRef={duLottieRef}
                      loop={false}
                      autoplay={false}
                      animationData={serviceFront}
                      className="lottie_service"
                      style={serviceStyle}
                    />
                  )}
                </div>
              </div>
              <div className="arrow_area">
                {/*<p className="arrow_text">자세히 보기</p>*/}
                <Lottie animationData={arrow} className="lottie_arrow" onClick={popIconClick} />
              </div>
            </BannerWrap>
          </SwiperSlide>
          {/* <SwiperSlide> 
            <SloganWrap ref={elementRef} className="section" id="section1">
              <video className="video" autoPlay={true} loop muted playsInline="playsinline">
                <source src={duVision} type="video/mp4" />
                <strong>Your browser does not support the video tag.</strong>
              </video>
              <div className="container">
                <div className="container video_cation_area">
                  <div className="caption video_cation1">
                    금융사기, 휴대폰 분실, 개인정보 유출, 가족 돌봄의 어려움,<br/>세대간 소통 부족 등 사회문제를 해결하기 위해
                  </div>
                  <div className="sub_caption">
                    <div className="caption video_cation2">
                      정보통신기술(ICT) 분야의 기술력과 서비스 경험을 바탕으로
                    </div>
                    <div className="caption video_cation3">
                      웹 3.0시대에 맞는 지능적이고 발전된 Global ICT 서비스를 만들어갑니다.
                    </div>
                  </div>
                </div>
              </div>
            </SloganWrap>
          </SwiperSlide> */}
        </Swiper>
      </SwiperWrap>
      <SectionWrap1 className="container version_section" id="section1">
        <div className="version_item">
          <div className="version_area">
            <h2 className="version">DU 1.0</h2>
          </div>
          <div className="version_content">
            <figure className="version_img">
              {isPhone && <img src={miniDu1} alt="" />}
              {isTablet && <img src={mobileDu1} alt="" />}
              {isPc && <img src={du1} alt="" />}
            </figure>
            <h3 className="version_title">기술로 사회문제를 해결합니다.</h3>
            <p className="version_detail">
              데이터유니버스는 ICT 기술을 활용해 금융사기, 개인정보 유출, 자산 보호 등의 개인에게
              발생할 수 있는 문제를 해결하고자 스마트피싱보호(Anti-Scam), 휴대폰분실보호(MFinder),
              오토콜(Autocall)을 출시하였습니다. 새롭게 발생하는 위협으로부터 개인을 보호하기 위해
              노력하고 있습니다.
            </p>
            <div className="icon_area">
              <div className="icon_wrap">
                <img src={antiScamIcon} alt="" />
                <span className="icon_title">Anti-Scam</span>
              </div>
              <div className="icon_wrap">
                <img src={mfinderIcon} alt="" />
                <span className="icon_title">MFinder</span>
              </div>
              <div className="icon_wrap">
                <img src={autocallIcon} alt="" />
                <span className="icon_title">AutoCall</span>
              </div>
            </div>
          </div>
        </div>
        <div className="version_item">
          <div className="version_area">
            <h2 className="version">DU 2.0</h2>
          </div>
          <div className="version_content">
            <figure className="version_img">
              {isPhone && <img src={miniDu2} alt="" />}
              {isTablet && <img src={mobileDu2} alt="" />}
              {isPc && <img src={du2} alt="" />}
            </figure>
            <h3 className="version_title">연결을 통한 상생과 소통에 함께합니다.</h3>
            <p className="version_detail">
              데이터유니버스는 사람을 연결하고 함께 성장할 수 있는 플랫폼을 제공하기 위해
              휴대폰가족보호, 이벤트U를 출시하였습니다. 기술이 주는 편리함으로 사람들에게 행복과
              혜택을 줄 수 있는 서비스를 지속적으로 만들어 가겠습니다.
            </p>
            <div className="icon_area">
              <div className="icon_wrap">
                <img src={familycareIcon} alt="" />
                <span className="icon_title">FamilyCare</span>
              </div>
              <div className="icon_wrap">
                <img src={eventuIcon} alt="" />
                <span className="icon_title">EventU</span>
              </div>
              <div className="icon_wrap">
                <img src={mfinderIcon} alt="" />
                <span className="icon_title">MFinder Global</span>
              </div>
              <div className="icon_wrap">
                <img src={couponwalletIcon} alt="" />
                <span className="icon_title">CouponWallet</span>
              </div>
            </div>
          </div>
        </div>
        <div className="version_item">
          <div className="version_area">
            <h2 className="version">DU 3.0</h2>
          </div>
          <div className="version_content">
            <figure className="version_img">
              {isPhone && <img src={miniDu3} alt="" />}
              {isTablet && <img src={mobileDu3} alt="" />}
              {isPc && <img src={du3} alt="" />}
            </figure>
            <h3 className="version_title">안전한 맞춤·지능형 서비스를 제공합니다.</h3>
            <p className="version_detail">
              데이터유니버스는 앞으로도 마이데이터 분석, AI를 활용한 에이징테크(AgeingTech),
              인슈어테크 등 다양한 분야에서 사람과 함께할 수 있는 맞춤형 서비스를 제공할 수 있도록
              나아가겠습니다.
            </p>
            <p className="unique">DATAUNIVERSE, Make a better Tomorrow</p>
          </div>
        </div>
      </SectionWrap1>
      <SectionWrap2
        ref={(el) => (sectionRef.current[1] = el)}
        id="section2"
        className="container patent_cert_section section"
      >
        <PatentModal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          imgType={imgType}
          patentsImg={patentsImg}
        />
        <h2 className="section_title">특허&인증</h2>
        <hr className="section_bar" />
        <div className="item_wrap">
          <h3 className="sub_title">특허 리스트</h3>
          <div className="top patent_list_wrap">
            <ul className="patent_list">
              {patentList.map((item) => (
                <li key={item.Idx}>
                  <span
                    className="patent_title"
                    onClick={() => {
                      setPatentsImg(item.ImagePath)
                      openModal()
                    }}
                  >
                    [특허 {item.PatentNum}] {item.PatentName}
                  </span>
                  <span className="patent_reg_dt">{formatYmd(item.PatentDate)}</span>
                </li>
              ))}
            </ul>
            {patentTotalNum > PATENT_PAGE_SIZE && (
              <Pagination
                className="pagination"
                patentTotalNum={patentTotalNum}
                setPatentPageNum={setPatentPageNum}
                divisionNum={PATENT_PAGE_SIZE}
              />
            )}
          </div>
        </div>
        <div className="item_wrap">
          <h3 className="sub_title">인증 내역</h3>
          {isMobile ? (
            <div className="patent_list_wrap">
              <figure className="main_img">
                <img src={mobileCert} alt="" />
              </figure>
              <div className="sub_img">
                <button
                  onClick={() => {
                    setImgType(0)
                    setPatentsImg(false)
                    openModal()
                  }}
                >
                  <figure className="">
                    <img src={mobileKoita} alt="" />
                  </figure>
                </button>
                <button
                  onClick={() => {
                    setImgType(1)
                    setPatentsImg(false)
                    openModal()
                  }}
                >
                  <figure className="">
                    <img src={mobileInnobiz} alt="" />
                  </figure>
                </button>
                <button
                  onClick={() => {
                    setImgType(2)
                    setPatentsImg(false)
                    openModal()
                  }}
                >
                  <figure className="">
                    <img src={mobileHc} alt="" />
                  </figure>
                </button>
              </div>
            </div>
          ) : (
            <div className="patent_list_wrap">
              <figure className="main_img">
                <img src={cert} alt="" />
              </figure>
              <div className="sub_img">
                <button
                  onClick={() => {
                    setImgType(0)
                    setPatentsImg(false)
                    openModal()
                  }}
                >
                  <figure className="">
                    <img src={certKoita} alt="" />
                  </figure>
                </button>
                <button
                  onClick={() => {
                    setImgType(1)
                    setPatentsImg(false)
                    openModal()
                  }}
                >
                  <figure className="">
                    <img src={certInnobiz} alt="" />
                  </figure>
                </button>

                <button
                  onClick={() => {
                    setImgType(2)
                    setPatentsImg(false)
                    openModal()
                  }}
                >
                  <figure className="">
                    <img src={certHc} alt="" />
                  </figure>
                </button>
              </div>
            </div>
          )}
        </div>
      </SectionWrap2>

      <SectionWrap3
        ref={(el) => (sectionRef.current[2] = el)}
        id="section3"
        className="container section"
      >
        <h2 className="section_title">히스토리</h2>
        <hr />
        {historyList.map((item) => (
          <div key={item.Year} className="history_item">
            <div className="history_area">
              <h3 className="year">{item.Year}</h3>
            </div>
            <div className="history_content w_history_content">
              <ul>
                {item.histories.map((history) => {
                  const arr = [
                    <li key={`h${history.Idx}`}>
                      <span className="month">{history.Month}</span>
                      <div className="history_detail">
                        {history.Content.split('\n').map((line, index) => (
                          <span key={index}>
                            {line}
                            <br />
                          </span>
                        ))}
                      </div>
                    </li>,
                  ]
                  if (history.ImageData) {
                    arr.push(
                      <li key={`himg_${history.Idx}`}>
                        {isMobile ? (
                          <img
                            src={history.MoImageData ?? history.ImageData}
                            alt=""
                            className={history.ImageType}
                          />
                        ) : (
                          <img src={history.ImageData} alt="" className={history.ImageType} />
                        )}
                      </li>,
                    )
                  }
                  return arr
                })}
              </ul>
            </div>
          </div>
        ))}
        {hasMoreHistory && !isLoadingHistory && (
          <MoreButton onClick={nextHistoryPage} disabled={isLoadingHistory}>
            더보기
          </MoreButton>
        )}
        {isLoadingHistory && (
          <div className="loader-area">
            <Loader width={40} height={40} className="loader-spinner" />
          </div>
        )}
      </SectionWrap3>

      <SectionWrap4 ref={(el) => (sectionRef.current[3] = el)} id="section4" className="section">
        <div className="top container">
          <h2 className="section_title">파트너</h2>
          <span className="section_info">
            데이터유니버스와 함께하는{isMobile ? <br /> : ' '}든든한 파트너입니다.
          </span>
        </div>
        <RollingPartner />
      </SectionWrap4>

      <SectionWrap5 ref={(el) => (sectionRef.current[4] = el)} id="section5" className="section">
        <div className="container">
          <h2 className="section_title">CI</h2>
          <hr />
          <div className="item_wrap">
            <h3 className="sub_title">Symbol</h3>
            <div className="right_wrap">
              <div className="center_img_box">
                <img src={symbol} alt="" />
              </div>
              <p className="ci item_desc">
                데이터유니버스의 심볼은 데이터 분석 과정을 이미지로 형상화 한 것입니다. 수 많은
                ‘데이터’를 기반으로 더 나은 ‘유니버스’를 만들고자 하는 우리의 목표를 담았습니다.
              </p>
            </div>
          </div>
          <div className="color item_wrap">
            <h3 className="sub_title">Color</h3>
            <div className="right_wrap">
              <div className="center_img_box">
                <img src={ci} alt="" />
              </div>
              <p className="color item_desc">
                기본으로 DU Red 및 지정된 검정색과 회색을 사용하며, 밝은 배경에서 사용할 것을
                권장합니다. <br />
                브랜드 고유 이미지를 나타내기 위해 색상, 형태 변형을 금합니다.
              </p>
              <MainButton3 className="ci_btn" onClick={ciDownload}>
                데이터유니버스 CI 다운로드
              </MainButton3>
            </div>
          </div>
        </div>
      </SectionWrap5>
      <SectionWrap6 ref={(el) => (sectionRef.current[5] = el)} className="section" id="section6">
        <div className="container contact_section">
          <div className="">
            <h3 className="">
              서울특별시 영등포구
              <br />
              국제금융로 2길 32 <br />
              7층
            </h3>
            <span className="sub_info">여의도역 3번출구 도보 2분</span>
            {/*<div className="tel_wrap">
              <a className="tel_number" href="tel:02-6013-0625">
                Tel : 02-6013-0625
              </a>
              <span className="fax_number">Fax : 02-6105-6396</span>
            </div>*/}
            <div className="contact_wrap">
              <span>고객문의 : contact@datau.co.kr</span>
              <span>제휴문의 : biz@datau.co.kr</span>
            </div>
          </div>
          <MapComponent />
        </div>
      </SectionWrap6>
      <TopButton scroll={swiperRef} />
    </>
  )
}
export default Information
