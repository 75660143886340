import styled, { keyframes } from 'styled-components'
import STYLE_GUIDE from 'service/constant/color'
import { useEffect, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useLocation} from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Mousewheel } from 'swiper'
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/mousewheel'
import { DefaultTabButton, BlackTabButton } from 'styles/common/tabButton'
import career1 from 'assets/images/recruit/img_career01_506.png'
import career2 from 'assets/images/recruit/img_career02_506.png'
import career3 from 'assets/images/recruit/img_career03_506.png'
import career4 from 'assets/images/recruit/img_career04_506.png'
import office1 from 'assets/images/recruit/KakaoTalk_20220510_142828030_03 1.png'
import office2 from 'assets/images/recruit/KakaoTalk_20220510_142828030_08 1.png'
import office3 from 'assets/images/recruit/KakaoTalk_20220510_142828030 2.png'
import office4 from 'assets/images/recruit/KakaoTalk_20220510_142828030_01 1.png'
import office5 from 'assets/images/recruit/image 413.png'
import office6 from 'assets/images/recruit/Group 1803.png'
import moffice1 from 'assets/images/recruit/Group 1923.png'
import moffice2 from 'assets/images/recruit/Group 1920.png'
import moffice3 from 'assets/images/recruit/Group 1919.png'
import moffice4 from 'assets/images/recruit/Group 1921.png'
import moffice5 from 'assets/images/recruit/Group 1922.png'
import moffice6 from 'assets/images/recruit/Group 1924.png'
import jobkorea from 'assets/images/recruit/img_jobkorea_507.png'
import saramin from 'assets/images/recruit/img_saramin_507.png'
import arrow from 'assets/icon/Arrow 1 (Stroke).png'
import mjobkorea from 'assets/images/recruit/img_jobkorea_367.png'
import msaramin from 'assets/images/recruit/img_saramin_367.png'
import careerSub1 from 'assets/images/recruit/img_career-sub01_150.png'
import careerSub2 from 'assets/images/recruit/img_career-sub02_150.png'
import careerSub3 from 'assets/images/recruit/img_career-sub03_150.png'
import careerSub4 from 'assets/images/recruit/img_career-sub04_233.png'
import careerSub5 from 'assets/images/recruit/img_career-sub05_233.png'
import careerSub6 from 'assets/images/recruit/img_career-sub06_233.png'
import careerSub7 from 'assets/images/recruit/img_career-sub07_233.png'
import careerSub8 from 'assets/images/recruit/img_career-sub08_233.png'
import careerSub9 from 'assets/images/recruit/img_career-sub09_233.png'
import careerSub10 from 'assets/images/recruit/img_career-sub10_233.png'
import careerSub11 from 'assets/images/recruit/img_career-sub11_233.png'
import careerSub12 from 'assets/images/recruit/img_career-sub12_233.png'
import careerSub13 from 'assets/images/recruit/img_career-sub13_233.png'
import careerSub14 from 'assets/images/recruit/img_career-sub14_233.png'
import banner from 'assets/images/recruit/img_recruit_1920.png'
import banner2 from 'assets/images/recruit/img_recruit_1200.jpg'
import TopButton from "../component/common/TopButton";
import {Parallax} from "react-parallax";

const fadeInUp = keyframes`
0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
}
to {
    opacity: 1;
    transform: translateZ(0);
}
`

const RecruitWrap = styled.div`
  padding: 220px 0 0 0;
  color: ${STYLE_GUIDE.COLOR.black1};

  .banner {
    // width: 100%;
    height: 380px;
    // background: rebeccapurple;
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: 50% 50%;
    .react-parallax-bgimage {
      top : -150%;
    }
  }

  .recruit_top {
    .title {
      font-weight: 700;
      font-size: 56px;
      line-height: 72px;
    }

    .tab_area {
      display: flex;
      padding: 50px 0 80px;

      >button: first-child {
        margin: 0 14px 0 0;
      }
    }
  }

  .welfare_section {
    padding: 150px 0 0;

    .section.first {
      display: flex;
      justify-content: space-between;
      padding: 0 0 120px;

      .left_section {
        flex: 0.9;
      }

      .right_section {
        flex: 1.9;
      }

      .right_section.image_wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 40px;
        grid-column-gap: 40px;

        img {
          border-radius: 40px;
        }
      }
    }

    .section {
      display: flex;
      justify-content: flex-start;
      padding: 0px 0px 120px;

      .left_section {
        width: 507px;
        padding: 0 40px 0 0;

        .title {
          font-weight: 700;
          font-size: 46px;
          line-height: 58px;
          color: #252525;
        }

        .description {
          font-weight: 400;
          font-size: 18px;
          line-height: 30px;
          color: #252525;
          padding: 20px 0 0;
        }
      }

      .right_section {
        display: flex;

        .icon_area {
          display: flex;
          flex-direction: column;
          align-items: center;


          .welfare_icon {
            width: 233px;
            border-radius: 40px;
          }

          .description {
            padding: 10px 0 0;
            font-weight: 700;
            font-size: 18px;
            line-height: 30px;
            text-align: left;
            width: 100%;
          }
        }
      }

      .right_section:not(.etc) {
        .icon_area {
          padding: 0 20px 0 0;
        }
      }

      .right_section.etc {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        row-gap: 50px;
        column-gap: 40px;
      }
    }
  }

  .office_section {
    .title {
      font-weight: 700;
      font-size: 46px;
      line-height: 58px;
    }
  }

  .office_image_wrap {
    overflow: hidden;

    .image_box {
      transition: all 0.2s ease;
      position: relative;

      .office_image {
        max-width: none;
        border-radius: 40px;
      }

      .text_wrap {
        transition: 0.5s ease;
        opacity: 0;
        transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        .text {
          color: ${STYLE_GUIDE.COLOR.white};
          font-weight: 700;
          font-size: 28px;
          line-height: 40px;
        }
      }

      &:hover .office_image {
        filter: brightness(50%);
      }

      &:hover .text_wrap {
        opacity: 1;
      }

      &:hover .text {
        animation: ${fadeInUp} 0.5s;
      }
    }

  }

  .swiper {
    width: 100%;
    height: 380px;
    margin: 40px 0 133px;
  }

  .swiper-wrapper.smooth_swiper {
    // -webkit-transition-timing-function: linear !important;
    // -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    width: 780px !important;

    /* Center slide text vertically */
    // display: -webkit-box;
    // display: -ms-flexbox;
    // display: -webkit-flex;
    // display: flex;
    // -webkit-box-pack: center;
    // -ms-flex-pack: center;
    // -webkit-justify-content: center;
    // justify-content: center;
    // -webkit-box-align: center;
    // -ms-flex-align: center;
    // -webkit-align-items: center;
    // align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .recurit_section {
    // margin: 553px 0 0;
    background: ${STYLE_GUIDE.COLOR.gray4};

    .section {
      display: flex;
      justify-content: space-between;
      height: 388px;

      .left_section {
        padding: 0 40px 0 0;

        .title {
          padding: 100px 0 0;
          font-weight: 700;
          font-size: 46px;
          line-height: 58px;
        }

        .description {
          font-weight: 400;
          font-size: 18px;
          line-height: 30px;
          padding: 20px 0 0;
        }
        .bottom.description {
          color: ${STYLE_GUIDE.COLOR.black3};
        }
      }

      .right_section {
        padding: 100px 0 0;

        .first_section,
        .second_section {
          display: flex;

          .item:first-child {
            padding: 0 40px 0 0;
          }

          .item:hover {
            cursor: pointer;

            .arrow_icon {
              // left: 45%;
              // bottom: 20px;
              transform: translate(8px, -8px);
            }
          }

          .go_item {
            border-radius: 20px;
          }

          .recurit_wrap {
            display: flex;
            align-items: center;
            padding: 10px 0 0;
            position: relative;

            .recurit_name {
              font-weight: 700;
              font-size: 28px;
              line-height: 40px;
            }

            .arrow_icon {
              // position: absolute;
              // left: 44%;
              // bottom: 9;
              padding: 0 0 0 12px;
              transition: 0.2s ease;
            }
          }
        }

        .second_section {
          padding: 64px 0 0;
        }
      }
    }
  }

  @media screen and (max-width: 1919px) and (min-width: 1200px) {
    .banner {
      .react-parallax-bgimage {
        top : -50%;
      }
    }
    .welfare_section {
      padding: 82px 0 0;

      .section {
        padding: 0 0 120px;
        .left_section {
          width: 313px;
          padding: 0 31px 0 0;

          .title {
          }

          .description {
          }
        }

        .right_section {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          column-gap: 40px;
          width: calc(100% - 344px);

          &.middle {
            row-gap: 27px;
          }

          &.middle2 {
            row-gap: 77px;
          }

          .icon_area {
            .welfare_icon {
              width: 191px;
            }
            .description {
              width: 100%;
            }
          }
        }

        .right_section:not(.etc) {
          .icon_area {
            padding: 0;
          }
        }

        .right_section.etc {
          grid-template-columns: 1fr 1fr 1fr;
          row-gap: 50px;
          column-gap: 40px;
        }
      }
    }

    .recurit_section {
      // margin: 420px 0 0;

      .section {
        display: block;
        height: 588px;

        .left_section {
          .title {
          }

          .description {
          }
          .bottom.description {
          }
        }

        .right_section {
          padding: 34px 0 0;

          .first_section,
          .second_section {
            .item:first-child {
              padding: 0 30px 0 0;
            }

            .item:hover {
              .arrow_icon {
              }
            }

            .go_item {
              width: 485px;
            }

            .recurit_wrap {
              .recurit_name {
              }

              .arrow_icon {
              }
            }
          }

          .second_section {
            padding: 40px 0 0;
          }
        }
      }
    }

    .office_section {
      .office_image_wrap {
        padding: 40px 0 120px;

        .image_box {
          .office_image {
            width: 534px;
          }
        }
      }
    }

    .swiper {
      margin: 40px 0 120px;
      height: auto;
    }

    .swiper-slide {
      width: 543px !important;
    }
  }

  @media screen and (max-width: 1199px) and (min-width: 884px) {
    padding: 100px 0 0;
    .banner {
      .react-parallax-bgimage {
        top : -50%;
      }
    }

    .recruit_top {
      .title {
        font-size: 36px;
        line-height: 48px;
      }

      .tab_area {
        display: none;
      }
    }

    .banner {
      height: 240px;
      margin: 70px 0 0;
    }

    .welfare_section {
      padding: 80px 0 0;

      .section.first {
        display: block;
        padding: 0 0 70px;

        .left_section {
        }

        .right_section {
        }

        .right_section.image_wrap {
          gap: 20px;
          img {
          }
        }
      }

      .section {
        display: block;
        padding: 0 0 80px;

        .left_section {
          width: 100%;
          .title {
            font-size: 28px;
            line-height: 40px;
          }

          .description {
            font-size: 16px;
            line-height: 30px;
            padding: 10px 0 0px;
          }
        }

        .right_section {
          padding: 30px 0 0;

          .icon_area {
            .description {
              width: 175px;
              font-size: 16px;
              line-height: 26px;
              //padding: 0px 0px 0px 10px;
            }

            .welfare_icon {
              width: 176px;
            }
          }
        }

        .right_section:not(.etc) {
          .icon_area {
          }
        }

        .right_section.etc {
          grid-template-columns: 1fr 1fr 1fr 1fr;
          gap: 20px;
        }
      }
    }

    .office_section {
      .title {
        font-size: 28px;
        line-height: 40px;
      }
    }

    .office_image_wrap {
      .m_image_box {
        .m_office_image {
          border-radius: 30px;
        }

        .m_text_wrap {
          padding: 10px 0 0;
          text-align: left;
          .m_text {
            font-weight: 700;
            font-size: 18px;
            line-height: 30px;
          }
        }
      }
    }

    .swiper {
      margin: 30px 0 67px;
      height: 240px;
    }

    .swiper-slide {
      width: 300px !important;
    }

    .swiper-slide img {
    }

    .recurit_section {
      // margin: 337px 0 0;
      .section {
        display: block;
        height: 380px;

        .left_section {
          .title {
            font-size: 28px;
            line-height: 40px;
            padding: 50px 0 0;
          }

          .description {
            font-size: 16px;
            line-height: 30px;
            padding: 10px 0 0;
          }
          .bottom.description {
            font-size: 16px;
            line-height: 30px;
          }
        }

        .right_section {
          padding: 30px 0 0;

          .first_section,
          .second_section {
            .item:first-child {
              padding: 0 30px 0 0;
            }

            .recurit_wrap {
              .recurit_name {
                font-size: 18px;
                line-height: 30px;
              }

              .arrow_icon {
                width: 8px;
                padding: 0 0 0 4px;
              }
            }
          }

          .second_section {
            padding: 30px 0 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: 883px) {
    padding: 100px 0 0;
    .banner {
      .react-parallax-bgimage {
        top : 0%;
      }
    }
    .recruit_top {
      .title {
        font-size: 36px;
        line-height: 48px;
      }

      .tab_area {
        display: none;
      }
    }

    .banner {
      height: 240px;
      margin: 70px 0 0;
    }

    .welfare_section {
      padding: 80px 0 0;

      .section.first {
        display: block;
        padding: 0 0 70px;
        .left_section {
        }

        .right_section {
        }

        .right_section.image_wrap {
          grid-template-columns: 1fr;
          gap: 20px;
          img {
          }
        }
      }

      .section {
        display: block;
        padding: 0 0 60px;

        .left_section {
          padding: 0 0 30px;
          width: 100%;
          .title {
            font-size: 28px;
            line-height: 40px;
          }

          .description {
            font-size: 16px;
            line-height: 26px;
            padding: 10px 0 0px;
          }
        }

        .right_section {
          //display: block;
          // padding: 30px 0 0;
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;
        
          .icon_area {
            img {
              width: 50px;
            }
            .welfare_icon {
              width: 150px;
              border-radius: 30px;
            }
            .description {
              width: 100%;
            }
          }

          //.icon_area:not(:last-child) {
          //  padding: 0 0 16px;
          //}
        }

        .right_section:not(.etc) {
          .icon_area {
            padding: 0;
          }
        }

        .right_section.etc {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;
        }
      }
    }

    .office_section {
      .title {
        font-size: 28px;
        line-height: 40px;
      }
    }

    .office_image_wrap {
      margin: 0 0 0 20px;
      .m_image_box {
        .m_office_image {
          border-radius: 30px;
        }

        .m_text_wrap {
          padding: 10px 0 0;
          text-align: left;
          .m_text {
            font-weight: 700;
            font-size: 18px;
            line-height: 30px;
          }
        }
      }
    }

    .swiper {
      margin: 30px 0 50px;
      height: 240px;
    }

    .swiper-slide {
      width: 300px !important;
    }

    .swiper-slide img {
    }

    .recurit_section {
      .section {
        display: block;
        height: 554px;

        .left_section {
          padding: 0;
          .title {
            font-size: 28px;
            line-height: 40px;
            padding: 50px 0 0;
          }

          .description {
            font-size: 16px;
            line-height: 30px;
            padding: 10px 0 0;
          }
          .bottom.description {
            font-size: 16px;
            line-height: 30px;
            padding: 0;
          }
        }

        .right_section {
          padding: 0;
          .first_section,
          .second_section {
            display: block;
            padding: 30px 0 0;

            .item:first-child {
              padding: 0;
            }

            .recurit_wrap {
              .recurit_name {
                font-size: 18px;
                line-height: 30px;
              }
              .arrow_icon {
                width: 8px;
                padding: 0 0 0 4px;
              }
            }
          }

          .first_section {
            .item:last-child {
              padding: 30px 0 0;
            }
          }
        }
      }
    }
  }
`

const Recruit = () => {
  const { state } = useLocation()
  const swiperRef = useRef()
  const isMobile = useMediaQuery({
    query: '(max-width:1199px)',
  })
  const isMobile2 = useMediaQuery({
    query: '(max-width:883px)',
  })

  const goJob = () => {
    const section = document.querySelector('.recurit_section')

    const toScroll = section.offsetTop - document.querySelector('.header').offsetHeight

    window.scrollTo({ top: toScroll, behavior: 'smooth' })
  }

  useEffect(() => {
    setTimeout(() => {
      if (state?.state === 2) {
        goJob()
      }
    }, 300)
  }, [state?.state])


  const handleMouseEnter = (e) => {
    document.querySelector('.swiper-wrapper').classList.remove('smooth_swiper')
    if (swiperRef.current.swiper !== null) {
      swiperRef?.current?.swiper?.autoplay?.stop()
    }
  }

  const handleMouseLeave = (e) => {
    document.querySelector('.swiper-wrapper').classList.add('smooth_swiper')
    if (swiperRef?.current?.swiper !== null) {
      swiperRef?.current?.swiper?.autoplay?.start()
    }
  }

  return (<>
    <RecruitWrap isMobile={isMobile}>
      <div className="recruit_top container">
        <h2 className="title">
          다양한 성장의 기회
          <br />
          데이터유니버스와 함께하세요.
        </h2>
        <div className="tab_area">
          <BlackTabButton>DU와 함께해요</BlackTabButton>
          <DefaultTabButton onClick={() => goJob()}>채용공고</DefaultTabButton>
        </div>
      </div>
      <Parallax
        className="banner"
        bgImage={isMobile ? banner2 : banner}
        strength={300}
        bgImageStyle={{minWidth: '480px', minHeight: '400px'}}
      >
      </Parallax>
      <div className="welfare_section container">
        <div className="section first">
          <div className="left_section">
            <h3 className="title">주 36.5시간 일해요!</h3>
            <p className="description">
              일은 늦게 시작하고, 주말은 빨리 시작하는 우리. 점심까지 제공하니 돈 쓸 일이 없어요.
            </p>
          </div>
          <div className="right_section image_wrap">
            <img src={career1} alt="" />
            <img src={career2} alt="" />
            <img src={career3} alt="" />
            <img src={career4} alt="" />
          </div>
        </div>
        <div className="section">
          <div className="left_section">
            <h3 className="title">
              발전하는
              {!isMobile ? <br /> : ''}
              그대를 위해
            </h3>
          </div>
          <div className="right_section middle">
            <div className="icon_area">
              <img className="welfare_icon" src={careerSub1} alt="" />
              <span className="description">교육비 지원</span>
            </div>
            <div className="icon_area">
              <img className="welfare_icon" src={careerSub2} alt="" />
              <span className="description">통신비 지원</span>
            </div>
            <div className="icon_area">
              <img className="welfare_icon" src={careerSub3} alt="" />
              <span className="description">도서구입 지원</span>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="left_section">
            <h3 className="title">
              열정은 보상으로
              {!isMobile ? <br /> : ''}
              돌려드려요
            </h3>
          </div>
          <div className="right_section middle2">
            <div className="icon_area">
              <img className="welfare_icon" src={careerSub4} alt="" />
              <span className="description">성과급 지급</span>
            </div>
            <div className="icon_area">
              <img className="welfare_icon" src={careerSub5} alt="" />
              <span className="description">우수사원 포상</span>
            </div>
            <div className="icon_area">
              <img className="welfare_icon" src={careerSub6} alt="" />
              <span className="description">장기근속자 포상</span>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="left_section">
            <h3 className="title">
              다닐 맛 나는
              {!isMobile ? <br /> : ''}
              다양한 복지들
            </h3>
          </div>
          <div className="right_section etc">
            {/*<div className="icon_area">*/}
            {/*  <img className="welfare_icon" src={careerSub7} alt="" />*/}
            {/*  {isMobile2 ? (*/}
            {/*    <span className="description">팀 회식비 인당 6만원 지급!</span>*/}
            {/*  ) : (*/}
            {/*    <span className="description">배부르게 먹어요~ 회식비 지급!</span>*/}
            {/*  )}*/}
            {/*</div>*/}
            <div className="icon_area">
              <img className="welfare_icon" src={careerSub8} alt="" />
              <span className="description">생일이 언제에요? 선물 드려요~</span>
            </div>
            <div className="icon_area">
              <img className="welfare_icon" src={careerSub9} alt="" />
              <span className="description">행복한 명절을 위한 선물!</span>
            </div>
            <div className="icon_area">
              <img className="welfare_icon" src={careerSub10} alt="" />
              <span className="description">경조휴가 및 경조금 지급</span>
            </div>
            <div className="icon_area">
              <img className="welfare_icon" src={careerSub11} alt="" />
              {isMobile2 ? (
                <span className="description">대명리조트 휴양 시설 운영</span>
              ) : (
                <span className="description">휴양 리조트 지원</span>
              )}
            </div>
            <div className="icon_area">
              <img className="welfare_icon" src={careerSub12} alt="" />
              <span className="description">연 1회 건강검진 및 단체예방접종</span>
            </div>
            <div className="icon_area">
              <img className="welfare_icon" src={careerSub13} alt="" />
              <span className="description">영화보고 퇴근하는 시네마데이!</span>
            </div>
            <div className="icon_area">
              <img className="welfare_icon" src={careerSub14} alt="" />
              <span className="description">다양한 간식과 커피 제공!</span>
            </div>
          </div>
        </div>
      </div>
      <div className="office_section container">
        <h3 className="title">DU사무실 랜선투어</h3>
      </div>
      <div className="office_image_wrap">
        {isMobile && (
          <Swiper
            spaceBetween={20}
            slidesPerView={'auto'}
            loop={true}
            modules={[Autoplay]}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false, // 쓸어 넘기거나 버튼 클릭 시 자동 슬라이드 정지.
            }}
            className="mySwiper"
            style={{ overflow: 'visible' }}
            // pauseOnMouseEnter={true}
            // loopedSlides={2}
            // centeredSlides={true}
          >
            <SwiperSlide>
              <figure className="m_image_box">
                <img className="m_office_image" src={moffice3} alt="" />
                <div className="m_text_wrap">
                  <p className="m_text">데이터유니버스에 어서오세요!</p>
                </div>
              </figure>
            </SwiperSlide>
            <SwiperSlide>
              <figure className="m_image_box">
                <img className="m_office_image" src={moffice2} alt="" />
                <div className="m_text_wrap">
                  <p className="m_text">맘이 편해지는 초록이들</p>
                </div>
              </figure>
            </SwiperSlide>
            <SwiperSlide>
              <figure className="m_image_box">
                <img className="m_office_image" src={moffice4} alt="" />
                <div className="m_text_wrap">
                  <p className="m_text">휴식은 감성돋는 카페테리아에서</p>
                </div>
              </figure>
            </SwiperSlide>
            <SwiperSlide>
              <figure className="m_image_box">
                <img className="m_office_image" src={moffice6} alt="" />
                <div className="m_text_wrap">
                  <p className="m_text">잠시 떠나요, DU 힐링스페이스</p>
                </div>
              </figure>
            </SwiperSlide>
            <SwiperSlide>
              <figure className="m_image_box">
                <img className="m_office_image" src={moffice5} alt="" />
                <div className="m_text_wrap">
                  <p className="m_text">깔끔하고 모던한 회의실에서 소통해요!</p>
                </div>
              </figure>
            </SwiperSlide>
            <SwiperSlide>
              <figure className="m_image_box">
                <img className="m_office_image" src={moffice1} alt="" />
                <div className="m_text_wrap">
                  <p className="m_text">커피와 간식이 마르지 않는 DU의 곳간</p>
                </div>
              </figure>
            </SwiperSlide>
          </Swiper>
        )}
        <div onMouseEnter={(e) => handleMouseEnter(e)} onMouseLeave={(e) => handleMouseLeave(e)}>
          {!isMobile && (
            <Swiper
              ref={swiperRef}
              spaceBetween={30}
              slidesPerView={'auto'}
              modules={[Autoplay, Mousewheel]}
              loop={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false, // 쓸어 넘기거나 버튼 클릭 시 자동 슬라이드 정지.
                reverseDirection: true,
              }}
              className="mySwiper"
              style={{ overflow: 'visible' }}
              slidesOffsetBefore={100}
              pagination={{
                clickable: true,
                mousewheelControl: true,
              }}
              mousewheel={true}
            >
              <SwiperSlide>
                <figure className="image_box">
                  <img className="office_image" src={office3} alt="" />
                  <div className="text_wrap">
                    <p className="text">데이터유니버스에 어서오세요!</p>
                  </div>
                </figure>
              </SwiperSlide>
              <SwiperSlide>
                <figure className="image_box">
                  <img className="office_image" src={office2} alt="" />
                  <div className="text_wrap">
                    <p className="text">맘이 편해지는 초록이들</p>
                  </div>
                </figure>
              </SwiperSlide>
              <SwiperSlide>
                <figure className="image_box">
                  <img className="office_image" src={office4} alt="" />
                  <div className="text_wrap">
                    <p className="text">휴식은 감성돋는 카페테리아에서</p>
                  </div>
                </figure>
              </SwiperSlide>
              <SwiperSlide>
                <figure className="image_box">
                  <img className="office_image" src={office6} alt="" />
                  <div className="text_wrap">
                    <p className="text">잠시 떠나요, DU 힐링스페이스</p>
                  </div>
                </figure>
              </SwiperSlide>
              <SwiperSlide>
                <figure className="image_box">
                  <img className="office_image" src={office5} alt="" />
                  <div className="text_wrap">
                    <p className="text">깔끔하고 모던한 회의실에서 소통해요!</p>
                  </div>
                </figure>
              </SwiperSlide>
              <SwiperSlide>
                <figure className="image_box">
                  <img className="office_image" src={office1} alt="" />
                  <div className="text_wrap">
                    <p className="text">커피와 간식이 마르지 않는 DU의 곳간</p>
                  </div>
                </figure>
              </SwiperSlide>
              <SwiperSlide>
                <figure className="image_box">
                  <img className="office_image" src={office3} alt="" />
                  <div className="text_wrap">
                    <p className="text">데이터유니버스에 어서오세요!</p>
                  </div>
                </figure>
              </SwiperSlide>
              <SwiperSlide>
                <figure className="image_box">
                  <img className="office_image" src={office2} alt="" />
                  <div className="text_wrap">
                    <p className="text">맘이 편해지는 초록이들</p>
                  </div>
                </figure>
              </SwiperSlide>
              <SwiperSlide>
                <figure className="image_box">
                  <img className="office_image" src={office4} alt="" />
                  <div className="text_wrap">
                    <p className="text">휴식은 감성돋는 카페테리아에서</p>
                  </div>
                </figure>
              </SwiperSlide>
              <SwiperSlide>
                <figure className="image_box">
                  <img className="office_image" src={office6} alt="" />
                  <div className="text_wrap">
                    <p className="text">잠시 떠나요, DU 힐링스페이스</p>
                  </div>
                </figure>
              </SwiperSlide>
              <SwiperSlide>
                <figure className="image_box">
                  <img className="office_image" src={office5} alt="" />
                  <div className="text_wrap">
                    <p className="text">깔끔하고 모던한 회의실에서 소통해요!</p>
                  </div>
                </figure>
              </SwiperSlide>
              <SwiperSlide>
                <figure className="image_box">
                  <img className="office_image" src={office1} alt="" />
                  <div className="text_wrap">
                    <p className="text">커피와 간식이 마르지 않는 DU의 곳간</p>
                  </div>
                </figure>
              </SwiperSlide>
            </Swiper>
          )}
        </div>
      </div>
      <div className="recurit_section">
        <div className="section container">
          <div className="left_section">
            <h3 className="title">DU와 함께 일해요!</h3>
            <p className="description">
              채용 포털사이트를 통해 채용공고를
              {!isMobile ? <br /> : ''}
              확인할 수 있습니다.
            </p>
            <p className="bottom description">채용관련 문의 : recruit@datau.co.kr</p>
          </div>
          <div className="right_section">
            <div className="first_section">
              <div className="item">
                <a href="https://www.jobkorea.co.kr/Recruit/Co_Read/Recruit/C/datauniverse" target="_blank" rel="noreferrer">
                  {isMobile ? (
                    <figure>
                      <img className="go_item" src={mjobkorea} alt="" />
                    </figure>
                  ) : (
                    <figure>
                      <img className="go_item" src={jobkorea} alt="" />
                    </figure>
                  )}
                  <div className="recurit_wrap">
                    <p className="recurit_name">잡코리아 모집공고</p>
                    <img className="arrow_icon" src={arrow} alt="" />
                  </div>
                </a>
              </div>
              <div className="item">
                <a href="https://www.saramin.co.kr/zf_user/company-info/view-inner-recruit?csn=ZmYxbk5TQUIyTmpodWxxN0FmNys0Zz09" target="_blank" rel="noreferrer">
                  {isMobile ? (
                    <figure>
                      <img className="go_item" src={msaramin} alt="" />
                    </figure>
                  ) : (
                    <figure>
                      <img className="go_item" src={saramin} alt="" />
                    </figure>
                  )}
                  <div className="recurit_wrap">
                    <p className="recurit_name">사람인 모집공고</p>
                    <img className="arrow_icon" src={arrow} alt="" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </RecruitWrap>
    <TopButton/>
  </>
  )
}
export default Recruit
