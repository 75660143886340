
import pcTopButton from 'assets/icon/ico_Quick_top_60.png'
import mobileTopButton from 'assets/icon/ico_Quick_top_40.png'
import { useMediaQuery } from 'react-responsive'

const TopButton = (props) => {
    const scroll = props?.scroll
    const isMobile = useMediaQuery({
        query: '(max-width:1119px)',
    })
    function scrollTo(offset, callback) {
        const fixedOffset = offset.toFixed();
        const onScroll = function () {
            if (window.pageYOffset.toFixed() === fixedOffset) {
                window.removeEventListener('scroll', onScroll)
                callback()
            }
        }
        window.addEventListener('scroll', onScroll)
        onScroll()
        window.scrollTo({
            top: offset,
            behavior: 'smooth'
        })
    }
    const slideUp = () => {
        scroll.current.swiper.enable();
        scroll.current.swiper.slideTo(0);
    }
    const goTop = () => {
        /*if(scroll) {
            const option = {
                threshold: 1.0
            }
            const io = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {

                    if(entry.isIntersecting){
                        scroll.current.swiper.enable();
                        scroll.current.swiper.slideTo(0);
                        io.unobserve(document.querySelector('#section1'))
                    }
                })
            }, option)
            io.observe(document.querySelector('#section1'))
        }*/
        if(scroll) {
            scrollTo(0, slideUp)
        } else {
            window.scrollTo({top:0, DDbehavior: 'smooth'})
        }
    }
    const btnStyle = {
        cursor: 'pointer',
        margin: isMobile ?  '30px 0' : '40px 0'
    }
    return (
        <div className="container">
            <div style={{display: 'flex', justifyContent: 'end'}}>
            {isMobile ? (<img src={mobileTopButton} alt={""} onClick={goTop} style={btnStyle}/>)
                : (<img src={pcTopButton} alt={""} onClick={goTop} style={btnStyle}/>)}
            </div>
        </div>
    )
}
export default TopButton
